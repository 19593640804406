import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';

Vue.use(Vuetify);

const vuetify = new Vuetify({
  theme: {
    themes: {
      light: {
        background: '#ffffff',
      },
      dark: {
        background: '#000000',
      },
    },
  },
});

if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
  vuetify.framework.theme.dark = true;
}

export default vuetify;
