<template>
  <div class="app__feedback-item slick-slide slick-current slick-center" data-slick-index="0" aria-hidden="true" style="width: 371px;" tabindex="0">
    <div class="app__feedback-top">
      <p class="app__feedback-appName">{{ review.title }}</p>
      <p class="app__feedback-date">{{ review.date }}</p>
    </div>
    <div class="app__feedback-middle">
      <div class="app__feedback-stars">
        <span class="app__feedback-star">
          <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 21 21">
            <linearGradient id="fb-0-100">
              <stop offset="100%" stop-opacity="1" stop-color="#fe8115"></stop>
              <stop offset="0%" stop-opacity="0" stop-color="#fe8115"></stop>
              <stop offset="0%" stop-opacity="0" stop-color="#fe8115"></stop>
              <stop offset="0%" stop-opacity="0" stop-color="#fe8115"></stop>
            </linearGradient>
            <path id="stroke" fill="url(#fb-0-100)" stroke="#fe8115" d="M8.3634879,9.7242952 L7.58494933,6.46048334 L10.1332207,4.27761756 L6.78856955,4.0094784 L5.5,0.911385646 L4.21143045,4.0094784 L0.866779258,4.27761756 L3.41505067,6.46048334 L2.6365121,9.7242952 L5.5,7.9752877 L8.3634879,9.7242952 Z"></path>
          </svg>
        </span>
        <span class="app__feedback-star">
          <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 21 21">
            <linearGradient id="fb-1-100">
              <stop offset="100%" stop-opacity="1" stop-color="#fe8115"></stop>
              <stop offset="0%" stop-opacity="0" stop-color="#fe8115"></stop>
              <stop offset="0%" stop-opacity="0" stop-color="#fe8115"></stop>
              <stop offset="0%" stop-opacity="0" stop-color="#fe8115"></stop>
            </linearGradient>
            <path id="stroke" fill="url(#fb-1-100)" stroke="#fe8115" d="M8.3634879,9.7242952 L7.58494933,6.46048334 L10.1332207,4.27761756 L6.78856955,4.0094784 L5.5,0.911385646 L4.21143045,4.0094784 L0.866779258,4.27761756 L3.41505067,6.46048334 L2.6365121,9.7242952 L5.5,7.9752877 L8.3634879,9.7242952 Z"></path>
          </svg>
        </span>
        <span class="app__feedback-star">
          <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 21 21">
            <linearGradient id="fb-2-100">
              <stop offset="100%" stop-opacity="1" stop-color="#fe8115"></stop>
              <stop offset="0%" stop-opacity="0" stop-color="#fe8115"></stop>
              <stop offset="0%" stop-opacity="0" stop-color="#fe8115"></stop>
              <stop offset="0%" stop-opacity="0" stop-color="#fe8115"></stop>
            </linearGradient>
            <path id="stroke" fill="url(#fb-2-100)" stroke="#fe8115" d="M8.3634879,9.7242952 L7.58494933,6.46048334 L10.1332207,4.27761756 L6.78856955,4.0094784 L5.5,0.911385646 L4.21143045,4.0094784 L0.866779258,4.27761756 L3.41505067,6.46048334 L2.6365121,9.7242952 L5.5,7.9752877 L8.3634879,9.7242952 Z"></path>
          </svg>
        </span>
        <span class="app__feedback-star">
          <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 21 21">
            <linearGradient id="fb-3-100">
              <stop offset="100%" stop-opacity="1" stop-color="#fe8115"></stop>
              <stop offset="0%" stop-opacity="0" stop-color="#fe8115"></stop>
              <stop offset="0%" stop-opacity="0" stop-color="#fe8115"></stop>
              <stop offset="0%" stop-opacity="0" stop-color="#fe8115"></stop>
            </linearGradient>
            <path id="stroke" fill="url(#fb-3-100)" stroke="#fe8115" d="M8.3634879,9.7242952 L7.58494933,6.46048334 L10.1332207,4.27761756 L6.78856955,4.0094784 L5.5,0.911385646 L4.21143045,4.0094784 L0.866779258,4.27761756 L3.41505067,6.46048334 L2.6365121,9.7242952 L5.5,7.9752877 L8.3634879,9.7242952 Z"></path>
          </svg>
        </span>
        <span class="app__feedback-star">
          <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 21 21">
            <linearGradient id="fb-4-100">
              <stop offset="100%" stop-opacity="1" stop-color="#fe8115"></stop>
              <stop offset="0%" stop-opacity="0" stop-color="#fe8115"></stop>
              <stop offset="0%" stop-opacity="0" stop-color="#fe8115"></stop>
              <stop offset="0%" stop-opacity="0" stop-color="#fe8115"></stop>
            </linearGradient>
            <path id="stroke" fill="url(#fb-4-100)" stroke="#fe8115" d="M8.3634879,9.7242952 L7.58494933,6.46048334 L10.1332207,4.27761756 L6.78856955,4.0094784 L5.5,0.911385646 L4.21143045,4.0094784 L0.866779258,4.27761756 L3.41505067,6.46048334 L2.6365121,9.7242952 L5.5,7.9752877 L8.3634879,9.7242952 Z"></path>
          </svg>
        </span>
      </div>
      <p class="app__feedback-userName">{{ review.name }}</p>
    </div>
    <div class="app__feedback-text">
      <p class="app__feedback-fullText">{{ review.text }}</p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: ['review'],
  components: {

  },
  data: () => ({

  }),
  methods: {

  },
  computed: {
    ...mapGetters([
      ''
    ])
  },
  mounted () {

  }
};
</script>

<style lang="css">
@media (prefers-color-scheme: dark) {
  .app__feedback-item {
    background: #1c1c1d!important;
  }

  .app__feedback-appName, .app__feedback-text {
    color: #fff!important;
  }
}

.slick-slide {
  padding-right: 5px;
}

.app__feedback {
	padding: 0 5vw 7vw;
	overflow-x: hidden
}

.app__feedback-item {
	background: #f0f0f8;
	border-radius: 10px;
	height: 54vw;
}

.app__feedback-item.slick-slide {
	margin-left: 0;
}

.app__feedback-item.slick-slide:last-child {
	margin-right: 0
}

.app__feedback-middle,
.app__feedback-top {
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-moz-box-orient: horizontal;
	-moz-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-moz-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-moz-box-align: center;
	-ms-flex-align: center;
	align-items: center
}

.app__feedback-appName {
	font-size: 3.7vw;
	font-weight: 600;
	color: #161617;
	white-space: nowrap;
	max-width: 72%;
	-o-text-overflow: ellipsis;
	text-overflow: ellipsis;
	word-spacing: unset;
	overflow: hidden
}

.app__feedback-date,
.app__feedback-userName {
	color: #b4b4b6;
	font-size: 3.5vw;
	font-weight: 400
}

.app__feedback-text {
	color: #333335;
	padding: 3vw 5vw 5vw;
	max-height: 9em;
	overflow: hidden;
	position: relative
}

.app__feedback-text p {
	font-size: 3.6vw;
  font-weight: 600;
  line-height: 1.3!important;
}

.app__feedback-text p a {
	position: absolute;
	bottom: 0;
	right: 5vw;
	text-decoration: none;
	color: #267ff9;
	font-size: 3.6vw;
  font-weight: 300;
	padding-left: 5vw;
	padding-bottom: .5vw
}

.app__feedback-top {
	padding: 4vw 5vw 1vw
}

.app__feedback-middle {
	padding: 0 5vw
}

.app__feedback-stars {
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-moz-box-orient: horizontal;
	-moz-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row
}

.app__feedback-star {
	height: 4vw;
	width: 3.8vw
}

.app__feedback-star svg {
	height: 6.8vw;
	width: 6vw
}
</style>
