<template>
  <section class="app__information">
    <h2 class="app__information-caption" data-l10n-id="information">{{ currentStore.translation.app_information_title }}</h2>
    <div class="app__information-table">
      <div class="app__information-seller">
        <p data-l10n-id="seller">{{ currentStore.translation.app_information_seller }}</p>
        <p>{{ currentStore.developer_name }}</p>
      </div>
      <div class="app__information-size">
        <p data-l10n-id="size">{{ currentStore.translation.app_information_size }}</p>
        <p>{{ currentStore.size }}</p>
      </div>
      <div class="app__information-category">
        <p data-l10n-id="category">{{ currentStore.translation.app_information_category }}</p>
        <p>{{ currentStore.chart_name }}</p>
      </div>
      <div class="app__information-compability">
        <p data-l10n-id="compatibility">{{ currentStore.translation.app_information_compatibility }}</p>
        <p data-l10n-id="works_on_this_iphone">{{ currentStore.translation.app_information_works }}</p>
      </div>
      <div class="app__information-languages">
        <p data-l10n-id="language">{{ currentStore.translation.app_information_languages }}</p>
        <p><span data-l10n-id="language_and">{{ currentStore.translation.app_information_english }}</span> <span>{{ currentStore.more_languages }}</span> <span data-l10n-id="something_more">{{ currentStore.translation.app_information_more }}</span></p>
      </div>
      <div class="app__information-age">
        <p data-l10n-id="age_rating">{{ currentStore.translation.app_information_age }}</p>
        <p>{{ currentStore.max_age }}</p>
      </div>
      <div class="app__information-inapp">
        <p data-l10n-id="in-app-purchases">{{ currentStore.translation.app_information_iap }}</p>
        <p>{{ currentStore.translation.app_information_si }}</p>
      </div>
      <div class="app__information-copyright">
        <p data-l10n-id="copyright">{{ currentStore.translation.app_information_copyright }}</p>
        <p>© {{ currentStore.developer_name }}</p>
      </div>
      <div class="app__information-site">
        <p data-l10n-id="developer_website">{{ currentStore.translation.app_information_website }}</p>
        <p><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAABGdBTUEAALGPC/xhBQAAA9NJREFUSA3FV0tolDEQ/vK3PlAKHqS2pYJYKJ6k4s0HCCIehFofKEV7UMGbggiW7lbcSlupIBVvgg8qgg8s1UpR9CIK4kGpR1EKomUVT6IH7Vobv0k2f/Lv2nUrYgPZmUxm5vuTmUmyCuW0jI7wA2ug0cK+miZ1UOzSNLL8zXL8gv025uApMmrKzJX4USXmgIyejwkcps5R9uqSun7yE9kzmIdz/IDvXpzkpgdO6x1cTT/70qRJmSOF99yBI+hRg7+zKAbWWqET3ZhCqsBgnI7uUDaCuRgjlS2WVoccGki38CO3ktaLMG4ReumtE0rpWEYmCSygKVyjfHegJPE7gSZcxi71M5AXszd1BV5iHz+gi5M2B6zWDcK3huBJ4LTuKVjpMBZhL9rV12KUEpI+XYXPuEqN5lhLVt6j0m7sgSWmU7jlJkjPMUGOlJOhgY1npRImmCMwyWnlEXa6mFtgyd4cXgeJNEzQbX8N6uAt+BCHduWScHPRKNkeGR0pGZ+9WbO9ZdSi8x9TWUCH3o7jWmqd5ch6llC5RBQMW56IWKsCLnVqmyTSTGPaodcipc9z1z7QySAmsdG5M77Ep29HBbPSnEj+cBg32euVpucyejmB2qjQxt1qYHdNM0w33cBQqYhRAy6lVi2YEQ1aYiWp0z+VTKdu4OoecMvGaJsxoLEDMgpPuMVvQ5Hxac8AKyamANt4WNFIwqBwkNJLmPnPaLOpcCoYDwR8yHrfxJT4+kK3J1KoXMifJOhiI1SMpMJd9q5A6RuzNixJP5X0XRfR0AO77PPqnsvoBQRtjQURV92rmilbGMvA2ymjvgTjkHVHrISDwOW2HHZRtcqoK7xCt3rEWNfSyZ7YRQWuxHwpRoOlZO9Tpxau3skcPeAY0jGCysHwjva1Ri6Hw0o8DHQK2dD3h0rOyhY0Gi17y7wutGDdNTKL18VyzZsobBLvCAdLVoT17ayyEuMXbkSadOgmctjv2CKq8BgV/Khudb9oLikIfT9XSOt1LJEneZ1xrMKyxJdndCVX+57zNbEfxRNKYYD9EgHfxPLpGLkuR/GW0/aujrC+0ryRJiDPFXna1Jv7FLhA3rYcNpOpIcgk6QjpRdrcY/bKuLwmd7QDBbH4LlPGskMfI+3Le5FLYkV8Xqd0E8E2UPk6wT7mdcon9m5+RQOXXO04pU5b4Fm7FuU1KA8z35oZ1/78zeWlM+Hk1svhLE38K0Qw8i9Pf4DIa1CeJ74dJvgQZKtm2sRGbDUOxab26TPoxnar3WjWHnvyAbPyvHUrF/rfH/Qh+Kz8hUl+wD//0/YL6oBaGVZtSAAAAAAASUVORK5CYII=" alt=""></p>
      </div>
      <div class="app__information-policy">
        <p data-l10n-id="privacy_policy">{{ currentStore.translation.app_information_pp }}</p>
        <p><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAeCAYAAADD0FVVAAACWUlEQVRIDe2WP2gUQRTGv292Lt5huBSCCqKFjYi5HBKMorGzsBHENlZp7CwUQeQia45EsJRga6O1YIKihSBWFokkpxjLBOxE8vfuctmbJ7PJXM67NetesHOat/Pe93775u3uzAIxIz9SvpUbWV/OFcrPIMIYeRhWcSIBihBmAQydLq73xeltvO3O+UL5thA+gKkTucz1+blK4ECGGPQE50HcF8FkqZgZAiku7mwbNFcorwHYH95RS78EnHZipXnRBPIWQMb6xDP5zw+651zcWT0wvnKgUvauoa4/lB7um3fAMAmqG9gpxAC2XSEwBNDG24euVvUUgHPQwcrA+MrxSrldlNSjxOBsmCTMbtRSJ5MCovR2OY2+bi8vSpfIF/tKJaJti/9DO+na7jn/rKeNT4ZGxppriJs3a/v81d7ee2uHrE+R2HBBMRh019bGzZ02X6iMSOCVqNTXM/7aYeYKlSVAepwgiRWNCwpSRZ0fRaDDXMoNBUij0iRAq2WdKQR86oAkatqoN78tPykUgrsCNDZuAZ58Gsss2A2l40oBuewKIbFkvM2inSuQe4A6pH2qGP/i9/zcgtpG73GQWDhyMPPYYezLv+wmnVqChdc3d1asCHzvFGbzCMzMjqafNzOUAd83OxJfa95pPVGV6NoLEvYETTxITM75mXetico+MQoftQb+Yr6Y8oLhKF24S83q9BjAV1GCP/gWxTNXpv3sj6j41tbn03QdTV+FYAJAPUrY5HvZpYP+qJ8Ip2mcpM6RH109ZTa9YQougTgGQIvgGxVnDOsTu8Ec4xcPmsV44Hk14QAAAABJRU5ErkJggg==" alt=""></p>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  components: {

  },
  data: () => ({

  }),
  methods: {

  },
  computed: {
    ...mapGetters([
      'currentStore'
    ])
  },
  mounted () {

  }
};
</script>

<style lang="css">

@media (prefers-color-scheme: dark) {
  .app__information-age p:last-child,
  .app__information-category p:last-child,
  .app__information-compability p:last-child,
  .app__information-copyright p:last-child,
  .app__information-inapp p:last-child,
  .app__information-languages p:last-child,
  .app__information-policy p:last-child,
  .app__information-seller p:last-child,
  .app__information-size p:last-child {
    color: #f7f7f7!important;
  }

  .app__information-age,
  .app__information-category,
  .app__information-compability,
  .app__information-copyright,
  .app__information-inapp,
  .app__information-languages,
  .app__information-seller,
  .app__information-site,
  .app__information-size {
  	border-bottom: 1px solid #1e1e20!important;
  }
}

.app__information {
	padding: 2vw 5vw 10vw
}

.app__information p {
	margin-bottom: 0px!important;
}

.app__information-caption {
	font-weight: 700;
	font-size: 5.5vw;
	line-height: 1.2;
	padding-bottom: 3.5vw
}

.app__information-age,
.app__information-category,
.app__information-compability,
.app__information-copyright,
.app__information-inapp,
.app__information-languages {
	border-bottom: 1px solid #E4E4E4
}

.app__information-age,
.app__information-category,
.app__information-compability,
.app__information-copyright,
.app__information-inapp,
.app__information-languages,
.app__information-policy,
.app__information-seller,
.app__information-site,
.app__information-size {
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-moz-box-orient: horizontal;
	-moz-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-moz-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	font-weight: 600
}

.app__information-seller,
.app__information-site,
.app__information-size {
	border-bottom: 1px solid #E4E4E4
}

.app__information-age p:first-child,
.app__information-category p:first-child,
.app__information-compability p:first-child,
.app__information-copyright p:first-child,
.app__information-inapp p:first-child,
.app__information-languages p:first-child {
	color: #939397;
	font-size: 3.6vw;
	padding-top: 3.6vw;
	padding-bottom: 3.6vw;
	font-weight: 500
}

.app__information-policy p:first-child {
	font-size: 3.6vw;
	padding-top: 3.6vw;
	padding-bottom: 3.6vw;
	font-weight: 500
}

.app__information-seller p:first-child {
	color: #939397
}

.app__information-seller p:first-child,
.app__information-site p:first-child,
.app__information-size p:first-child {
	font-size: 3.6vw;
	padding-top: 3.6vw;
	padding-bottom: 3.6vw;
	font-weight: 500
}

.app__information-size p:first-child {
	color: #939397
}

.app__information-age p:last-child,
.app__information-category p:last-child,
.app__information-compability p:last-child,
.app__information-copyright p:last-child,
.app__information-inapp p:last-child,
.app__information-languages p:last-child,
.app__information-policy p:last-child,
.app__information-seller p:last-child,
.app__information-site p:last-child,
.app__information-size p:last-child {
	color: #0f0f0f;
	font-size: 3.6vw;
	padding-top: 3.6vw;
	padding-bottom: 3.6vw
}

.app__information-category p:last-child {
	text-transform: capitalize
}

.app__information-policy {
	border-bottom: none
}

.app__information-policy p:first-child,
.app__information-site p:first-child {
	color: #0579fc
}

.app__information-policy img {
	max-width: 3vw;
	margin-right: .7vw
}

.app__information-site p:last-child {
	padding-bottom: 0
}

.app__information-site img {
	max-width: 5vw
}

.app__information-compability p:last-child,
.app__information-inapp p:last-child,
.app__information-languages p:last-child {
	position: relative;
	padding-right: 4vw
}

.app__information-compability p:last-child:after,
.app__information-inapp p:last-child:after,
.app__information-languages p:last-child:after {
	content: "";
	border-right: 2px solid #9d9da2;
	border-top: 2px solid #9d9da2;
	-webkit-transform: rotate(135deg);
	-ms-transform: rotate(135deg);
	transform: rotate(135deg);
	width: 7px;
	height: 7px;
	position: absolute;
	top: 50%;
	right: 0;
	margin-top: -5px;
	display: block
}

.app__information-table {
	padding-bottom: 35px
}
</style>
