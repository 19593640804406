<template>
  <v-row no-gutters justify="center">
    <v-col cols="11">
      <v-row no-gutters>
        <v-col cols="11">
          <span class="about__title">{{ currentAndroidStore.translation.about_title }}</span>
        </v-col>
        <v-col cols="1">
          <v-icon color="grey darken-2">arrow_forward</v-icon>
        </v-col>
      </v-row>
      <v-row class="mt-1" no-gutters>
        <v-col cols="12">
          <span class="about__summary">{{ currentAndroidStore.about }}</span>
        </v-col>
      </v-row>
      <v-row class="mt-2" no-gutters wrap>
        <v-col cols="12" class="charles">
          <v-chip-group>
          <v-chip small outlined v-for="category in currentAndroidStore.categories" :key="category" class="yayo mr-2">
            <span class="about__chip_title">{{ category }}</span>
          </v-chip>
          </v-chip-group>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  components: {

  },
  data: () => ({

  }),
  methods: {

  },
  computed: {
    ...mapGetters([
      'currentAndroidStore'
    ])
  },
  mounted () {

  }
};
</script>

<style lang="css">
.about__title {
  font-size: 1rem;
  font-weight: 500;
}

.about__summary {
  font-size: 0.8rem;
  font-weight: 400;
}

.about__chip_title {
  color: #424242;
  font-size: 0.7rem;
  font-weight: 500;
}
.charles {
  overflow-x: scroll!important;
}
.yayo {
  display: inline-block!important;
}
</style>
