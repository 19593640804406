<template>
  <section class="app__definition">
    <p :class="expanded ? 'mb-0 show' : 'mb-0 hide'"><pre @click="toggleExpanded"><span v-html="currentStore.description" /></pre></p>
    <a v-if="!expanded" @click="toggleExpanded" class="app__definition-show" data-l10n-id="more">{{ currentStore.translation.device_iphone_more }}</a>
    <a v-else @click="toggleExpanded" class="app__definition-show" data-l10n-id="more">{{ currentStore.translation.device_iphone_less }}</a>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  components: {

  },
  data: () => ({
    expanded: false
  }),
  methods: {
    toggleExpanded () {
      this.expanded = !this.expanded
    }
  },
  computed: {
    ...mapGetters([
      'currentStore'
    ])
  },
  mounted () {

  }
};
</script>

<style lang="css">
@media (prefers-color-scheme: dark) {
  .app__definition a {
    background: #000!important;
  }

  .app__definition a:before {
  	background: linear-gradient(90deg, rgba(255, 255, 255, 0), #000)!important;
  	background: -webkit-gradient(linear, left top, right top, from(rgba(255, 255, 255, 0)), to(#000))!important;
  	background: -o-linear-gradient(left, rgba(255, 255, 255, 0), #000)!important;
  }

  .app__definition p:first-child {
    color: #fff!important;
  }
}

.app__definition {
	padding: 4.8vw 5vw 0 0vw;
	overflow: hidden;
	position: relative
}

.app__definition p:first-child {
	color: #000;
	font-size: 3.55vw;
  font-weight: 500;
	line-height: 1.4;
}

.show {
  max-height: unset;
}

.hide {
  max-height: 4.2em
}

.app__definition p pre {
	font-family: unset;
  white-space: pre-wrap;       /* css-3 */
  white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
  white-space: -pre-wrap;      /* Opera 4-6 */
  white-space: -o-pre-wrap;    /* Opera 7 */
  word-wrap: break-word;       /* Internet Explorer 5.5+ */
}

.app__definition p:nth-child(2) {
	display: none
}

.app__definition a {
  color: #267ff9;
  text-decoration: none;
  position: absolute;
  bottom: 0;
  right: 5vw;
  background: #fff;
  font-size: 3.55vw;
  padding-left: 5vw;
}

.app__definition a:before {
  content: "";
  display: block;
  position: absolute;
  left: -30px;
  top: 0;
  bottom: 0;
  width: 30px;
  background: -o-linear-gradient(left,rgba(255,255,255,0),#fff);
  background: -webkit-gradient(linear,left top,right top,from(rgba(255,255,255,0)),to(#fff));
  background: linear-gradient(90deg,rgba(255,255,255,0),#fff);
}
</style>
