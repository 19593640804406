<template>
  <v-row no-gutters>
    <v-col cols="11">
      <v-row no-gutters>
        <v-col cols="3">
          <v-row no-gutters class="app__icon" justify="center">
            <v-col cols="8" align="center">
              <v-img class="mt-2" :src="currentAndroidStore.icon" />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="9">
          <v-row no-gutters>
            <span class="app__title">{{ currentAndroidStore.title }}</span>
          </v-row>
          <v-row no-gutters>
            <span class="app__subtitle">{{ currentAndroidStore.subtitle }}</span>
          </v-row>
          <v-row no-gutters>
            <span class="app__extra">{{ currentAndroidStore.translation.icon_title_extra }}</span>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  components: {

  },
  data: () => ({

  }),
  methods: {

  },
  computed: {
    ...mapGetters([
      'currentAndroidStore'
    ])
  },
  mounted () {

  }
};
</script>

<style scoped lang="scss">

.app__icon img {
	width: 20vw;
	height: 20vw;
	display: block;
	border-radius: 15px
}

.app__title {
  font-size: 1.5rem;
  font-weight: bold;
}

.app__subtitle {
  color: #00885e;
  font-size: 0.8rem;
  font-weight: 500;
}

.app__extra {
  color: grey;
  font-size: 0.7rem;
  font-weight: 500;
}

</style>
