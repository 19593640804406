<template>
  <v-row no-gutters justify="center">
    <v-col cols="11">
      <mwm-slick
        ref="slick"
        :options="slickOptions"
      >
        <div v-for="screen in currentAndroidStore.screenshots" :key="screen">
          <v-img class="mwm-image" eager contains :src="screen" />
        </div>
      </mwm-slick>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex';

import Slick from 'vue-slick';
import 'slick-carousel/slick/slick.css';

export default {
  components: {
    'mwm-slick': Slick
  },
  data: () => ({
    // player: null,
    // isPlayerMuted: true,

    screenshots: [

    ]
  }),
  methods: {
    // switchMute () {
    //   if (this.player === null) { return }
    //   if (this.player.muted) {
    //     this.player.muted = false;
    //     this.isPlayerMuted = false
    //   } else {
    //     this.player.muted = true;
    //     this.isPlayerMuted = true
    //   }
    // }
  },
  computed: {
    ...mapGetters([
      'currentAndroidStore'
    ]),
    slickOptions () {
      const slidesToShowCount = this.currentAndroidStore.screenshots_orientation === 'vertical' ? 3.5 : 1.3
      return {
        slidesToShow: slidesToShowCount,
        arrows: false,
        infinite: false
      }
    }
  },
  mounted () {
    // this.player = document.getElementById('video-element');
  }
};
</script>

<style lang="css">
.mwm-image .v-image__image {
  border-radius: 8px;
  width: 98%;
}

/* .video-container {
  position: relative;
}

.video-controls {
  position: absolute;
  bottom: 15px;
  right: 15px;
}

.mwm-video {
  width: 98%;
  height: 7.5rem;
  display: flex;
} */

</style>
