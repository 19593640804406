<template>
  <section class="app__developer">
    <div class="app__develop">
      <p class="app__developer-name">{{ currentStore.developer_name }}</p>
      <p class="app__developer-title" data-l10n-id="developer">{{ currentStore.translation.app_developer_developer }}</p>
    </div>
    <div class="app__developer-arrow"></div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  components: {

  },
  data: () => ({

  }),
  methods: {

  },
  computed: {
    ...mapGetters([
      'currentStore'
    ]),
  },
  mounted () {

  }
};
</script>

<style lang="css">
.app__developer {
	padding: 4.8vw 5vw 0 0vw;
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-moz-box-orient: horizontal;
	-moz-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-moz-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between
}

.app__developer-name {
	color: #267ff9;
  font-weight: 600;
	font-size: 3.8vw
}

.app__developer-title {
	color: #9d9da2;
	font-size: 3.5vw;
	padding-top: .4vw;
	font-weight: 600
}

.app__developer-arrow {
	width: 32px;
	height: 32px;
	position: relative
}

.app__developer-arrow:before {
	content: "";
	border-right: 2px solid #9d9da2;
	border-top: 2px solid #9d9da2;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
	width: 8px;
	height: 8px;
	position: absolute;
	top: 50%;
	right: 0;
	margin-top: -4px;
	display: block
}

.app__develop,
.footer>div {
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-moz-box-orient: vertical;
	-moz-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column
}
</style>
