import Vue from 'vue';
import Vuex from 'vuex';

import pagesService from '@/api/pages';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isMobile: false,
    currentStore: null,
    currentLanding: null,
    currentAndroidStore: null,
    attributionParams: null,
    currentInstallationId: '',
    currentPageId: '',
  },
  getters: {
    isMobile: state => state.isMobile,
    currentStore: state => state.currentStore,
    currentLanding: state => state.currentLanding,
    currentAndroidStore: state => state.currentAndroidStore,
    attributionParams: state => state.attributionParams,
    currentInstallationId: state => state.currentInstallationId,
    currentPageId: state => state.currentPageId,
  },
  mutations: {
    setIsMobile(state, isMobile) {
      state.isMobile = isMobile;
    },
    setCurrentStore(state, store) {
      state.currentStore = store;
    },
    setCurrentLanding(state, landing) {
      state.currentLanding = landing;
    },
    setCurrentAndroidStore(state, androidStore) {
      state.currentAndroidStore = androidStore;
    },
    setAttributionParams(state, params) {
      if (params.utm_source === null) {
        state.attributionParams = {
          utm_source: 'mwm_empty_utm_source',
          utm_campaign: 'mwm_empty_utm_campaign',
          adset: 'mwm_empty_adset',
          ad: 'mwm_empty_ad',
        };
        return;
      }
      state.attributionParams = params;
    },
    setInstallationId(state, id) {
      state.installationId = id;
    },
    setPageId(state, id) {
      state.currentPageId = id;
    },
  },
  actions: {
    fetchPage: (context, payload) => pagesService.getPage(payload)
      .then((response) => {
        context.commit('setCurrentStore', response.data);
        return Promise.resolve(response);
      })
      .catch(error => Promise.reject(error)),
    fetchLanding: (context, payload) => pagesService.getLanding(payload)
      .then((response) => {
        context.commit('setCurrentLanding', response.data);
        return Promise.resolve(response);
      })
      .catch(error => Promise.reject(error)),
    fetchAndroidPage: (context, payload) => pagesService.getAndroidPage(payload)
      .then((response) => {
        context.commit('setCurrentAndroidStore', response.data);
        return Promise.resolve(response);
      })
      .catch(error => Promise.reject(error)),
    sendPageConversionEvent: (context, payload) => pagesService.sendPageConversion(payload)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error)),
    sendAPageConversionEvent: (context, payload) => pagesService.sendAPageConversion(payload)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error)),
    sendLandingConversionEvent: (context, payload) => pagesService.sendLandingConversion(payload)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error)),
    sendEvent: ({ state }, payload) => {
      const evPayload = {
        installation_id: state.installationId,
        pixel_id: payload.pixel_id,
        event_type: payload.event_type,
        value: payload.value,
        page_id: state.currentPageId,
        source: state.attributionParams.utm_source,
        campaign_id: state.attributionParams.utm_campaign,
        adset_id: state.attributionParams.adset,
        ad_id: state.attributionParams.ad,
      };

      pagesService.sendEvent(evPayload)
        .then(response => Promise.resolve(response))
        .catch(error => Promise.reject(error));
    },
  },
  modules: {

  },
});
