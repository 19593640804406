<template>
  <v-row no-gutters justify="center">
    <v-col cols="11">
      <v-row no-gutters class="mb-5" v-for="comment in currentAndroidStore.reviews" :key="comment.icon">
        <v-col cols="12">
          <v-row no-gutters>
            <v-col cols="1">
              <v-avatar size="26"> <v-img :src="comment.avatar" /> </v-avatar>
            </v-col>
            <v-col cols="10">
              <span class="ml-3 comments__commentator">{{ comment.name }}</span>
            </v-col>
            <v-col cols="1">
              <!-- <v-icon color="grey darken-2">more_vertical</v-icon> -->
            </v-col>
          </v-row>
          <v-row no-gutters align="center" class="mt-1">
            <v-col cols="auto">
              <v-rating class="rating__star" color="#00885e" background-color="#00885e" half-increments v-model="comment.note" dense></v-rating>
            </v-col>
            <v-col cols="auto">
              <span class="ml-2 comments__date">{{ comment.date }}</span>
            </v-col>
          </v-row>
          <v-row no-gutters class="mt-1">
            <span :class="comment.expanded ? 'comments__comment' : 'comments__comment hide'" @click="comment.expanded = !comment.expanded">{{ comment.text }}</span>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  components: {

  },
  data: () => ({

  }),
  methods: {

  },
  computed: {
    ...mapGetters([
      'currentAndroidStore'
    ])
  },
  mounted () {

  }
};
</script>

<style lang="css">
.comments__commentator {
  font-size: 0.8rem;
}

.comments__date {
  font-size: 0.7rem;
}

.comments__comment {
  color: #616161;
  font-size: 0.8rem;
}

.hide {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
