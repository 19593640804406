<template>
  <div v-if="currentLanding != null" class="content">
    <top-bar />
    <body-screen v-view="visibilityChanged"
      v-for="(screen, index) in currentLanding.screens"
      :key="screen.url"
      :screen="screen"
      :index="index"
    />
    <stores-buttons />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import TopBar from './landing/TopBar.vue'
import Screen from './landing/Screen.vue'
import StoresButtons from './landing/StoresButtons.vue'

export default {
  components: {
    'top-bar': TopBar,
    'body-screen': Screen,
    'stores-buttons': StoresButtons,
  },
  data: () => ({
    scrolled25StorageKey: 'scrolled25StorageKey',
    scrolled50StorageKey: 'scrolled50StorageKey',
    scrolled75StorageKey: 'scrolled75StorageKey',
    scrolled100StorageKey: 'scrolled100StorageKey',
  }),
  methods: {
    visibilityChanged (e) {
      // console.log('type', e.type, 'percentTop', e.percentTop, 'scrollPercent', e.scrollPercent);

      // console.log('type', e.type) // 'enter', 'exit', 'progress'
      // // console.log('percentInView', e.percentInView) // 0..1 how much element overlap the viewport
      // console.log('percentTop', e.percentTop) // 0..1 position of element at viewport 0 - above , 1 - below
      // // console.log('percentCenter', e.percentCenter) // 0..1 position the center of element at viewport 0 - center at viewport top, 1 - center at viewport bottom
      // console.log('scrollPercent', e.scrollPercent) // 0..1 current scroll position of page
      // // console.log('scrollValue', e.scrollValue) // 0..1 last scroll value (change of page scroll offset)
      // // console.log('target', e.target.rect) // element.getBoundingClientRect() result

      this.handleScroll(e.scrollPercent)
    },
    handleScroll (scrollPercent) {
      this.sendEventForScrollTargetIfNeeded(scrollPercent, 0.25, '25', this.scrolled25StorageKey)
      this.sendEventForScrollTargetIfNeeded(scrollPercent, 0.50, '50', this.scrolled50StorageKey)
      this.sendEventForScrollTargetIfNeeded(scrollPercent, 0.75, '75', this.scrolled75StorageKey)
      this.sendEventForScrollTargetIfNeeded(scrollPercent, 1, '100', this.scrolled100StorageKey)
    },
    sendEventForScrollTargetIfNeeded (scrollPercent, trgtPercent, trgt, key) {
      if (scrollPercent >= trgtPercent) {
        if (!this.$session.exists(key)) {
          this.$session.set(key, true);
          this.sendEventForScrollTarget(trgt)
        }
      }
    },
    sendEventForScrollTarget (trgt) {
      this.$store.dispatch('sendEvent', { event_type: 'scroll_target_reached', value: JSON.stringify({ target: trgt }) })
    }
  },
  computed: {
    ...mapGetters([
      'currentLanding'
    ])
  },
  mounted () {

  },
};
</script>

<style lang="css">
  .content {
    background-image: url("/static/img/bg_desktop.png");
    /* background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    /* background-size: cover; /* Resize the background image to cover the entire container */
    background-color: black!important;
  }
  .landing-bg {

  }
</style>
