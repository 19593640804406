<template>
  <section class="app__header">
    <div class="app__icon">
      <img :src="currentStore.icon">
    </div>
    <div class="app__description">
      <div class="app__names">
        <h1 class="app__name">{{ currentStore.title }}</h1>
        <div class="app__subname">{{ currentStore.subtitle }}</div>
      </div>
      <div class="app__get">
        <a @click="sendEventToPixel" class="app__get_button ui-link">
          <span class="white--text">{{ currentStore.translation.icon_title_cta_text }}</span>
        </a>
        <div class="app__get-container" data-l10n-id="in-app-purchases" v-html="currentStore.translation.icon_title_cta_side_text"></div>
        <div class="app__get-dots">
          <p>{{ currentStore.translation.icon_title_dots }}</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';

const uuidv4 = require('uuid/v4')

export default {
  components: {

  },
  data: () => ({

  }),
  methods: {
    sendEventToPixel () {
      const eventId = uuidv4()
      window.fbq('trackSingleCustom', this.currentStore.pixel_id, 'OnClick', { placement: 'main_cta' }, { eventID: eventId });

      window.ttq.track('ClickButton', {})

      this.$store.dispatch('sendEvent', { event_type: 'on_click', value: JSON.stringify({ placement: 'main_cta' }) })

      const payload = {
        source: this.currentStore.id,
        data: [
          {
            event_name: 'OnClick',
            event_id: eventId,
            custom_data: {
              placement: 'main_cta'
            }
          }
        ]
      }
      const params = this.attributionParams
      const pid = params.utm_source
      const campaignId = params.utm_campaign
      const adsetId = params.adset
      const adId = params.ad
      const url = `${this.currentStore.attribution_url}?pid=${pid}&c=${campaignId}&af_c_id=${campaignId}&af_adset_id=${adsetId}&af_ad_id=${adId}`
      this.$store.dispatch('sendPageConversionEvent', payload)
        .then(() => {
          const win = window.open(url, '_self')
          win.focus()
        })
    }
  },
  computed: {
    ...mapGetters([
      'currentStore',
      'attributionParams'
    ])
  },
  mounted () {

  }
};
</script>

<style lang="css">
@media (prefers-color-scheme: dark) {
  .app__name {
    color: white!important;
  }
}

.app__description,
.app__header {
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-direction: normal;
	-moz-box-direction: normal
}

.app__header {
	padding: 5vw 3.5vw 0 5vw;
	-webkit-box-orient: horizontal;
	-webkit-flex-direction: row;
	-moz-box-orient: horizontal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-moz-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start
}

.app__icon img {
	width: 29vw;
	height: 29vw;
	display: block;
	border-radius: 25px
}

.app__description {
	color: #1a1a1a;
	line-height: 1.2;
	letter-spacing: .2px;
	padding-left: 4vw;
	-webkit-box-orient: vertical;
	-webkit-flex-direction: column;
	-moz-box-orient: vertical;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-moz-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	width: 100%;
}

.app__name {
	font-weight: 600;
	font-size: 5.4vw;
	line-height: 1.2;
}

.app__subname {
	font-size: 3.65vw;
	color: #9d9da2;
	padding-top: 1.1vw;
	white-space: nowrap;
	overflow: hidden;
	-o-text-overflow: ellipsis;
	text-overflow: ellipsis
}

.app__get {
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-moz-box-orient: horizontal;
	-moz-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	margin-top: 3vw;
	color: #929da2
}

.app__get a,
.app__get-dots {
	height: 30px;
	background: #007aff;
	text-align: center
}

.app__get a {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	color: #fff;
	font-size: 14.8px;
	font-weight: 700;
	padding: 0 8px;
	position: relative;
	text-decoration: none;
	text-transform: uppercase;
	line-height: 30px;
	min-width: 72px;
	border-radius: 28px
}

.app__get-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-moz-box-orient: vertical;
	-moz-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-moz-box-align: start;
	-ms-flex-align: start;
	align-items: flex-start;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-moz-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	padding-left: 8px;
	font-size: 2.8vw
}

.app__get-dots {
	width: 30px;
	border-radius: 50%;
	margin-left: auto
}

.app__get-dots p {
	color: #fff;
	font-size: 26px;
	font-weight: 700;
	line-height: 16px;
	letter-spacing: -1.8px;
	padding-right: 0
}
</style>
