import Vue from 'vue';

import VueSessionStorage from 'vue-sessionstorage';
import VueCheckView from 'vue-check-view';

import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import '@babel/polyfill';

Vue.use(VueSessionStorage);
Vue.use(VueCheckView);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app');
