<template>
  <section>
    <div class="app__ratings">
      <div class="app__rating">
        <div class="app__rating-item">
          <div class="app__rating-item-title">
            <span data-description-number="" class="app__rating-description-number">{{ currentStore.ratings }}</span>
            <span data-l10n-id="ratings"> {{ currentStore.translation.meta_data_ratings }}</span>
          </div>
          <div class="app__rating-item-value">{{ currentStore.note }}</div>
          <div class="app__rating-item-description">
            <div class="app__rating-stars">
              <span class="app__rating-star">
                <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 17 17">
                  <defs>
                    <linearGradient id="lg-ios14-0">
                      <stop :offset="getFirstFill()" stop-opacity="1" stop-color="#909095"></stop>
                      <stop offset="0%" stop-opacity="0" stop-color="#909095"></stop>
                    </linearGradient>
                  </defs>
                  <path id="stroke" fill="url(#lg-ios14-0)" stroke="#909095" d="M8.3634879,9.7242952 L7.58494933,6.46048334 L10.1332207,4.27761756 L6.78856955,4.0094784 L5.5,0.911385646 L4.21143045,4.0094784 L0.866779258,4.27761756 L3.41505067,6.46048334 L2.6365121,9.7242952 L5.5,7.9752877 L8.3634879,9.7242952 Z"></path>
                </svg>
              </span>
              <span class="app__rating-star">
                <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 17 17">
                  <defs>
                    <linearGradient id="lg-ios14-1">
                      <stop :offset="getSecondFill()" stop-opacity="1" stop-color="#909095"></stop>
                      <stop offset="0%" stop-opacity="0" stop-color="#909095"></stop>
                    </linearGradient>
                  </defs>
                  <path id="stroke" fill="url(#lg-ios14-1)" stroke="#909095" d="M8.3634879,9.7242952 L7.58494933,6.46048334 L10.1332207,4.27761756 L6.78856955,4.0094784 L5.5,0.911385646 L4.21143045,4.0094784 L0.866779258,4.27761756 L3.41505067,6.46048334 L2.6365121,9.7242952 L5.5,7.9752877 L8.3634879,9.7242952 Z"></path>
                </svg>
              </span>
              <span class="app__rating-star">
                <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 17 17">
                  <defs>
                    <linearGradient id="lg-ios14-2">
                      <stop :offset="getThirdFill()" stop-opacity="1" stop-color="#909095"></stop>
                      <stop offset="0%" stop-opacity="0" stop-color="#909095"></stop>
                    </linearGradient>
                  </defs>
                  <path id="stroke" fill="url(#lg-ios14-2)" stroke="#909095" d="M8.3634879,9.7242952 L7.58494933,6.46048334 L10.1332207,4.27761756 L6.78856955,4.0094784 L5.5,0.911385646 L4.21143045,4.0094784 L0.866779258,4.27761756 L3.41505067,6.46048334 L2.6365121,9.7242952 L5.5,7.9752877 L8.3634879,9.7242952 Z"></path>
                </svg>
              </span>
              <span class="app__rating-star">
                <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 17 17">
                  <defs>
                    <linearGradient id="lg-ios14-3">
                      <stop :offset="getFourthFill()" stop-opacity="1" stop-color="#909095"></stop>
                      <stop offset="0%" stop-opacity="0" stop-color="#909095"></stop>
                    </linearGradient>
                  </defs>
                  <path id="stroke" fill="url(#lg-ios14-3)" stroke="#909095" d="M8.3634879,9.7242952 L7.58494933,6.46048334 L10.1332207,4.27761756 L6.78856955,4.0094784 L5.5,0.911385646 L4.21143045,4.0094784 L0.866779258,4.27761756 L3.41505067,6.46048334 L2.6365121,9.7242952 L5.5,7.9752877 L8.3634879,9.7242952 Z"></path>
                </svg>
              </span>
              <span class="app__rating-star">
                <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 17 17">
                  <defs>
                    <linearGradient id="lg-ios14-4">
                      <stop :offset="getFifthFill()" stop-opacity="1" stop-color="#909095"></stop>
                      <stop offset="0%" stop-opacity="0" stop-color="#909095"></stop>
                    </linearGradient>
                  </defs>
                  <path id="stroke" fill="url(#lg-ios14-4)" stroke="#909095" d="M8.3634879,9.7242952 L7.58494933,6.46048334 L10.1332207,4.27761756 L6.78856955,4.0094784 L5.5,0.911385646 L4.21143045,4.0094784 L0.866779258,4.27761756 L3.41505067,6.46048334 L2.6365121,9.7242952 L5.5,7.9752877 L8.3634879,9.7242952 Z"></path>
                </svg>
              </span>
            </div>
          </div>
        </div>
        <div class="app__rating-item-separator"></div>
        <div class="app__rating-item">
          <div class="app__rating-item-title" data-l10n-id="age">{{ currentStore.translation.meta_data_age }}</div>
          <div class="app__rating-item-value">{{ currentStore.max_age }}</div>
          <div class="app__rating-item-description" data-l10n-id="years_old">{{ currentStore.translation.meta_data_years_old }}</div>
        </div>
        <div class="app__rating-item-separator"></div>
        <div class="app__rating-item">
          <div class="app__rating-item-title" data-l10n-id="chart">{{ currentStore.translation.meta_data_chart }}</div>
          <div v-if="currentStore.language === 'EN'" class="app__rating-item-value app__rating-item-value_chart-en">{{ currentStore.chart_top }}</div>
          <div v-else class="app__rating-item-value app__rating-item-value_chart-other">{{ currentStore.chart_top }}</div>
          <div data-category-name="" class="app__rating-item-description app__rating-item-description_chart">{{ currentStore.chart_name }}</div>
        </div>
        <div class="app__rating-item-separator"></div>
        <div class="app__rating-item">
          <div class="app__rating-item-title" data-l10n-id="developer">{{ currentStore.translation.meta_data_developer }}</div>
          <div class="app__rating-item-value app__rating-item-value_icon">
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" viewBox="0 0 512 512" width="6.7vw" height="6.7vw">
              <g>
                <path d="M448,32H64c-17.7,0-32,14.3-32,32v384c0,17.7,14.3,32,32,32h384c17.7,0,32-14.3,32-32V64   C480,46.3,465.7,32,448,32z M64,0C28.6,0,0,28.6,0,64v384c0,35.4,28.6,64,64,64h384c35.4,0,64-28.6,64-64V64c0-35.4-28.6-64-64-64   H64z"></path>
                <path d="M64,480v-32c0-32,32-128,192-128s192,96,192,128v32H64z M256,288c53,0,96-43,96-96s-43-96-96-96s-96,43-96,96   S203,288,256,288z"></path>
              </g>
            </svg>
          </div>
          <div class="app__rating-item-description">{{ currentStore.developer_name }}</div>
        </div>
        <div class="app__rating-item-separator"></div>
        <div class="app__rating-item">
          <div class="app__rating-item-title" data-l10n-id="language">{{ currentStore.translation.meta_data_language }}</div>
          <div class="app__rating-item-value" data-l10n-id="short_language">{{ currentStore.language }}</div>
          <div class="app__rating-item-description">+ <span>{{ currentStore.more_languages }}</span> <span data-l10n-id="plus_more">{{ currentStore.translation.meta_data_more }}</span></div>
        </div>
        <div class="app__rating-item-separator"></div>
        <div class="app__rating-item">
          <div class="app__rating-item-title" data-l10n-id="size">{{ currentStore.translation.meta_data_size }}</div>
          <div class="app__rating-item-value">{{ currentStore.size }}</div>
          <div class="app__rating-item-description">{{ currentStore.translation.meta_data_mb }}</div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  components: {

  },
  data: () => ({

  }),
  methods: {
    getFirstFill () {
      const { note } = this.currentStore
      if (note >= 1) { return '100%' }
      return `${note * 100}%`
    },
    getSecondFill () {
      const { note } = this.currentStore
      if (note >= 2) { return '100%' }
      return `${(note - 1) * 100}%`
    },
    getThirdFill () {
      const { note } = this.currentStore
      if (note >= 3) { return '100%' }
      return `${(note - 2) * 100}%`
    },
    getFourthFill () {
      const { note } = this.currentStore
      if (note >= 4) { return '100%' }
      return `${(note - 3) * 100}%`
    },
    getFifthFill () {
      const { note } = this.currentStore
      if (note >= 5) { return '100%' }
      return `${(note - 4) * 100}%`
    }
  },
  computed: {
    ...mapGetters([
      'currentStore'
    ])
  },
  mounted () {

  }
};
</script>

<style lang="css">
.app__ratings {
	width: 100%;
	overflow-x: scroll;
	padding-top: 3vw
}

.app__rating-item {
	width: 30vw;
	padding: 0vw 0vw;
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-moz-box-orient: vertical;
	-moz-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-moz-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	color: #909095
}

/* .app__rating-item:first-child {
	padding-left: 7vw
}

.app__rating-item:last-child {
	padding-right: 7vw
} */

.app__rating-item-separator {
	width: 1px;
	height: 100%;
	padding: 5.5vw 0;
	background-color: #2a2a2a
}

.app__rating-item-title {
	font-size: 2.8vw;
  font-weight: 600;
	text-transform: uppercase;
	white-space: nowrap
}

.app__rating-item-value {
	font-family: Varela Round, -apple-system, BlinkMacSystemFont, sans-serif;
	font-size: 5.5vw;
	font-weight: 600;
	margin-top: 2.2vw
}

.app__rating-item-value_chart-en:before {
	content: "No";
	font-size: 70%;
	top: -.3em;
	position: relative;
	font-weight: 600
}

.app__rating-item-value_chart-other:before {
	content: "#";
	font-size: 70%;
	top: -.3em;
	position: relative;
	font-weight: 400
}

.app__rating-item-value_icon svg {
	display: block
}

.app__rating-item-value_icon path {
	fill: #909095;
	fill-rule: evenodd;
	clip-rule: evenodd
}

.app__rating-item-description {
	font-size: 3.3vw;
	margin-top: 1.8vw;
  font-weight: 600;
	white-space: nowrap;
	width: 100%;
	position: relative;
	overflow: hidden;
	padding: 0 2vw;
	text-align: center;
}

/* .app__rating-item-description:after {
	content: "";
	display: block;
	position: absolute;
	top: 0;
	right: 0;
	background: -o-linear-gradient(left, rgba(255, 255, 255, 0), #fff);
	background: -webkit-gradient(linear, left top, right top, from(rgba(255, 255, 255, 0)), to(#fff));
	background: linear-gradient(90deg, rgba(255, 255, 255, 0), #fff);
	width: 2vw;
	height: 100%
} */

.app__rating-item-description_chart {
	text-transform: capitalize
}

.app__rating,
.app__rating-stars {
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
  margin-left: 3.5vw!important;
	display: flex
}

.app__rating {
	width: -webkit-max-content;
	width: -moz-max-content;
	width: max-content;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-moz-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	position: relative
}

/* .app__rating:before {
	content: "";
	display: block;
	height: 1px;
	width: -webkit-calc(100% - 10vw);
	width: calc(100% - 10vw);
	position: absolute;
	top: 0;
	left: 5vw;
	background-color: #e1e1e2
} */

.app__rating-stars {
	margin-top: .2vw;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-moz-box-orient: horizontal;
	-moz-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
}

.app__rating-star {
	height: 3.7vw;
	width: 4vw
}

.app__rating-star svg {
	height: 5vw;
	width: 5vw
}

.app__feedback-star path,
.app__rating-star path {
	height: 6vw;
	width: 7vw
}

.app__slider {
	padding: 0 0 4vw 5vw;
	overflow-x: hidden;
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-moz-box-orient: vertical;
	-moz-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column
}
</style>
