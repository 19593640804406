import Vue from 'vue';
import VueRouter from 'vue-router';

import Landing from '@/components/Landing.vue';
import Store from '@/components/Store.vue';
import FakeLanding from '@/components/FakeLanding.vue';
import StoreAndroid from '@/components/StoreAndroid.vue';

Vue.use(VueRouter);

const routes = [
  { path: '*', redirect: '/' },
  { path: '/', name: 'landing', component: Landing },
  { path: '/v/:pageId', name: 'store', component: Store },
  { path: '/ld/:landingId', name: 'fake-landing', component: FakeLanding },
  { path: '/av/:androidPageId', name: 'store-android', component: StoreAndroid },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
