<template>
  <section class="app__new">
    <div class="app__new-top">
      <h2 class="app__new-caption" data-l10n-id="what_is_new">{{ currentStore.translation.whats_new_title }}</h2>
      <p class="app__new-verisonHistory" data-l10n-id="version_history">{{ currentStore.translation.whats_new_history }}</p>
    </div>
    <div class="app__new-version-date">
      <p class="app__new-version"><span data-l10n-id="version">{{ currentStore.translation.whats_new_version }}</span> {{ currentStore.whats_new_version }}</p>
      <p class="app__new-date">{{ currentStore.whats_new_timing }}</p>
    </div>
    <div class="app__new-description-yoyo">
      <p :class="expanded ? 'mwm-show' : 'mwm-hide'"><pre @click="toggleExpanded"><span v-html="currentStore.whats_new_text" /></pre></p>
      <a v-if="!expanded" @click="toggleExpanded" class="app__new-more ui-link" data-l10n-id="more">{{ currentStore.translation.whats_new_more }}</a>
      <a v-else @click="toggleExpanded" class="app__new-more ui-link" data-l10n-id="more">{{ currentStore.translation.whats_new_less }}</a>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  components: {

  },
  data: () => ({
    expanded: false
  }),
  methods: {
    toggleExpanded () {
      this.expanded = !this.expanded
    }
  },
  computed: {
    ...mapGetters([
      'currentStore',
    ]),
  },
  mounted () {

  }
};
</script>

<style lang="css">

@media (prefers-color-scheme: dark) {
  .app__new-caption {
    color: #ffffff!important;
  }

  .app__new-description-yoyo p {
    color: #fefefe!important;
  }

  .app__definition a, .app__new-more {
    background: #000!important;
  }

  .app__definition a:before, .app__new-more:before {
    background: linear-gradient(90deg,rgba(0,0,0,0),#000)!important;
  }
}

.app__new {
	padding: 2.7vw 5vw 7.5vw
}

.app__new-caption {
	font-weight: 700;
	font-size: 5.5vw;
	line-height: 1.2
}

.app__new-top,
.app__new-version-date {
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-moz-box-orient: horizontal;
	-moz-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-moz-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between
}

.app__new-top {
	-webkit-box-align: center;
	-webkit-align-items: center;
	-moz-box-align: center;
	-ms-flex-align: center;
	align-items: center
}

.app__new-verisonHistory {
	font-size: 4vw;
  font-weight: 600;
	color: #4788ee
}

.app__new-date,
.app__new-version {
	padding: 2.8vw 0;
	color: #939396;
	font-size: 3.7vw
}

.app__new-description-yoyo {
	position: relative
}

.app__new-description-yoyo p {
	color: #1a1a1a;
	font-size: 3.55vw;
	overflow: hidden;
	line-height: 1.4;
}

.app__new-description-yoyo p pre {
	font-family: unset;
  white-space: pre-wrap;       /* css-3 */
  white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
  white-space: -pre-wrap;      /* Opera 4-6 */
  white-space: -o-pre-wrap;    /* Opera 7 */
  word-wrap: break-word;       /* Internet Explorer 5.5+ */
}

.mwm-show {
  height: unset;
}

.mwm-hide {
  height: 4rem;
}

.app__new-more {
	color: #267ff9;
	text-decoration: none;
	position: absolute;
	bottom: -1rem;
	right: 0;
	background: #fff;
	font-size: 3.55vw;
  font-weight: 500;
	padding-left: 5vw
}

.app__new-more:before {
	content: "";
	display: block;
	position: absolute;
	left: -30px;
	top: 0;
	bottom: 0;
	width: 30px;
	background: -o-linear-gradient(left, rgba(255, 255, 255, 0), #fff);
	background: -webkit-gradient(linear, left top, right top, from(rgba(255, 255, 255, 0)), to(#fff));
	background: linear-gradient(90deg, rgba(255, 255, 255, 0), #fff)
}
</style>
