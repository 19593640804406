<template>
  <v-row no-gutters>
    <v-col cols="12">
      <v-row no-gutters class="mt-5" align="center" justify="space-around">
        <v-col cols="3">
          <v-img contain :src="currentLanding.app_logo" />
        </v-col>
        <v-col cols="5" justify="center">
          <v-btn @click="sendEventToPixel" class="landing-main-cta">
            <span class="landing-main-cta-text">{{ currentLanding.main_cta }}</span>
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex';

const uuidv4 = require('uuid/v4')

export default {
  components: {

  },
  data: () => ({

  }),
  methods: {
    sendEventToPixel () {
      const eventId = uuidv4()

      // Send to MWM backend
      this.$store.dispatch('sendEvent', { pixel_id: this.currentLanding.pixel_id, event_type: 'on_click', value: JSON.stringify({ placement: 'top_cta' }) })

      const payload = {
        source: this.currentLanding.id,
        data: [
          {
            event_name: 'OnClick',
            event_id: eventId,
            custom_data: {
              placement: 'top_cta'
            }
          }
        ]
      }
      const params = this.attributionParams
      const pid = params.utm_source
      const campaignId = params.utm_campaign
      const adsetId = params.adset
      const adId = params.ad
      const url = `${this.currentLanding.attribution_url}?pid=${pid}&c=${campaignId}&af_c_id=${campaignId}&af_adset_id=${adsetId}&af_ad_id=${adId}`

      if (this.currentLanding.pixel_id.includes('AW-')) {
        // SEND TO GOOGLE ADS
        window.gtag('event', 'conversion', {
          send_to: 'AW-377188406/bm7wCI6OsowCELbg7bMB',
          transaction_id: eventId
        });

        const win = window.open(url, '_self')
        win.focus()
      } else {
        // SEND TO FACEBOOK + HANDLE CONVERSION
        window.fbq('trackSingleCustom', this.currentLanding.pixel_id, 'OnClick', { placement: 'top_cta' }, { eventID: eventId });

        this.$store.dispatch('sendLandingConversionEvent', payload)
          .then(() => {
            const win = window.open(url, '_self')
            win.focus()
          })
      }
    }
  },
  computed: {
    ...mapGetters([
      'attributionParams',
      'currentLanding'
    ])
  },
  mounted () {

  }
};
</script>

<style scoped lang="css">
.landing-main-cta {
  background: linear-gradient(295.35deg, #0041E8 20.5%, #2562FF 78.87%);
  box-shadow: 0px 5px 21px rgba(1, 129, 255, 0.5);
  border-radius: 42px;
  text-align: center;
}

.landing-main-cta-text {
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  padding: 10px;
  color: white;
}
</style>
