<template>
  <v-row no-gutters :class="index !== 0 ? 'screen-container' : 'first-screen-container'">
    <v-col cols="12">
      <v-row no-gutters justify="center">
        <span class="screen-title" v-html="screen.title" />
      </v-row>
      <div>
        <img :class="index === 3 ? 'screen-img-container-margintop' : 'screen-img-container'" :src="screen.url" >
      </div>
      <v-row class="screen-text-container" no-gutters justify="center">
        <v-btn @click="sendEventToPixel(index.toString())" class="screen-cta">
          <span class="screen-cta-text">{{ screen.text }}</span>
          <v-icon color="white" size="24" class="ml-5" right>east</v-icon>
        </v-btn>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex';

const uuidv4 = require('uuid/v4')

export default {
  props: ['screen', 'index'],
  components: {

  },
  data: () => ({

  }),
  methods: {
    sendEventToPixel (index) {
      const eventId = uuidv4()

      // Send to MWM backend
      this.$store.dispatch('sendEvent', { event_type: 'on_click', value: JSON.stringify({ placement: 'screen_cta', subplacement: index }) })

      const payload = {
        source: this.currentLanding.id,
        data: [
          {
            event_name: 'OnClick',
            event_id: eventId,
            custom_data: {
              placement: 'screen_cta',
              subplacement: index
            }
          }
        ]
      }
      const params = this.attributionParams
      const pid = params.utm_source
      const campaignId = params.utm_campaign
      const adsetId = params.adset
      const adId = params.ad
      const url = `${this.currentLanding.attribution_url}?pid=${pid}&c=${campaignId}&af_c_id=${campaignId}&af_adset_id=${adsetId}&af_ad_id=${adId}`

      if (this.currentLanding.pixel_id.includes('AW-')) {
        // SEND TO GOOGLE ADS
        window.gtag('event', 'conversion', {
          send_to: 'AW-377188406/bm7wCI6OsowCELbg7bMB',
          transaction_id: eventId
        });

        const win = window.open(url, '_self')
        win.focus()
      } else {
        // SEND TO FACEBOOK + HANDLE CONVERSION
        window.fbq('trackSingleCustom', this.currentLanding.pixel_id, 'OnClick', { placement: 'screen_cta', subplacement: index }, { eventID: eventId });

        this.$store.dispatch('sendLandingConversionEvent', payload)
          .then(() => {
            const win = window.open(url, '_self')
            win.focus()
          })
      }
    }
  },
  computed: {
    ...mapGetters([
      'attributionParams',
      'currentLanding'
    ])
  },
  mounted () {

  }
};
</script>

<style scoped lang="css">
  .screen-title {
    font-style: normal;
    font-weight: 800;
    font-size: 28px;
    line-height: 28px;
    text-align: center;
    color: white;
  }

  .first-screen-container {
    margin-top: 10vw!important;
  }

  .screen-container {
    margin-top: 20vw!important;
  }

  .screen-img-container {
    /* margin-top: 5vw!important; */
    width: 100%;
    /* mask-image: linear-gradient(to bottom, rgba(0,0,0,1), rgba(0,0,0,0.4)); */
  }

  .screen-img-container-margintop {
    margin-top: 15vw!important;
    width: 100%;
    /* mask-image: linear-gradient(to bottom, rgba(0,0,0,1), rgba(0,0,0,0.4)); */
  }

  .screen-text-container {
    /* margin-top: 5vw!important; */
  }

  .screen-cta {
    background: linear-gradient(295.35deg, #0041E8 20.5%, #2562FF 78.87%);
    box-shadow: 0px 5px 21px rgba(1, 129, 255, 0.5);
    border-radius: 42px;
    text-align: center;
    width: 60%;
    height: 50px!important;
    text-transform: unset;
  }

  .screen-cta-text {
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    color: white;
  }
</style>
