<template>
  <v-row no-gutters justify="center" class="metadata__container">
    <v-col cols="3" align="center">
      <v-row no-gutters align="center" justify="center">
        <v-col cols="auto">
          <span class="metadata__grade">{{ currentAndroidStore.note }}</span>
        </v-col>
        <v-col cols="auto">
          <v-icon class="metadata__star" small color="black">star</v-icon>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12">
          <span class="metadata__reviews">{{ currentAndroidStore.ratings }} {{ currentAndroidStore.translation.meta_data_reviews }}</span>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="1"  align="center">
      <v-divider vertical />
    </v-col>
    <v-col cols="3" align="center">
      <v-row no-gutters align="center" justify="center">
        <v-col cols="auto">
          <span class="metadata__dls">{{ currentAndroidStore.downloads }}</span>
        </v-col>
        <!-- <v-col cols="auto">
          <span class="metadata__plus">+</span>
        </v-col> -->
      </v-row>
      <v-row no-gutters>
        <v-col cols="12">
          <span class="metadata__reviews">{{ currentAndroidStore.translation.meta_data_downloads }}</span>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="1"  align="center">
      <v-divider vertical />
    </v-col>
    <v-col cols="3" align="center">
      <v-row no-gutters align="center" justify="center">
        <v-col cols="auto">
          <img src="https://play-lh.googleusercontent.com/f8B0enUmZD3qSV7UvP1aFSF5nQcAE_PpEJaXsIrfPMXU_D64BmVJC138JudhaYBkamCGbl_F3wXwfi0wJg=s14-rw" >
        </v-col>
      </v-row>
      <v-row no-gutters align="center" justify="center">
        <v-col cols="auto">
          <span class="metadata__reviews">PEGI 3</span>
        </v-col>
        <!-- <v-col cols="auto">
          <v-icon class="metadata__pegi_info" small color="grey">info</v-icon>
        </v-col> -->
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  components: {

  },
  data: () => ({

  }),
  methods: {

  },
  computed: {
    ...mapGetters([
      'currentAndroidStore'
    ])
  },
  mounted () {

  }
};
</script>

<style lang="css">
.metadata__container {
  line-height: 1.1!important;
}

.metadata__grade {
  font-size: 14px;
  font-weight: 500;
}

.metadata__star {
  font-size: 13px!important;
}

.metadata__reviews {
  color: grey;
  font-size: 12px;
  font-weight: 500;
}

.metadata__dls {
  font-size: 14px;
  font-weight: 500;
}

.metadata__plus {
  font-size: 12px;
  font-weight: 500;
}

.metadata__pegi_info {
  margin-left: 0.2rem;
  font-size: 10px!important;
}
</style>
