<template>
  <v-row no-gutters justify="center">
    <v-col cols="11">
      <v-btn @click="sendEventToPixel" small color="#01875f" block class="elevation-0 install__button">{{ currentAndroidStore.translation.install_cta }}</v-btn>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex';

const uuidv4 = require('uuid/v4')

export default {
  components: {

  },
  data: () => ({

  }),
  methods: {
    sendEventToPixel () {
      const eventId = uuidv4()

      // Send to MWM backend
      this.$store.dispatch('sendEvent', { event_type: 'on_click', value: JSON.stringify({ placement: 'install_cta' }) })

      const payload = {
        source: this.currentAndroidStore.id,
        data: [
          {
            event_name: 'OnClick',
            event_id: eventId,
            custom_data: {
              placement: 'install_cta'
            }
          }
        ]
      }

      let url = this.currentAndroidStore.attribution_url
      if (this.currentAndroidStore.attribution_url.includes('onelink.me')) {
        // the link is an AppsFlyer one
        const params = this.attributionParams
        const pid = params.utm_source
        const campaignId = params.utm_campaign
        const adsetId = params.adset
        const adId = params.ad
        url = `${this.currentAndroidStore.attribution_url}?pid=${pid}&c=${campaignId}&af_c_id=${campaignId}&af_adset_id=${adsetId}&af_ad_id=${adId}`
      }

      if (this.currentAndroidStore.pixel_id.includes('AW-')) {
        // SEND TO GOOGLE ADS
        // window.gtag('event', 'conversion', {
        //   send_to: 'AW-377188406/bm7wCI6OsowCELbg7bMB',
        //   transaction_id: eventId
        // });

        const win = window.open(url, '_self')
        win.focus()
      } else {
        // SEND TO FACEBOOK + HANDLE CONVERSION
        window.fbq('trackSingleCustom', this.currentAndroidStore.pixel_id, 'OnClick', { placement: 'install_cta' }, { eventID: eventId });

        this.$store.dispatch('sendAPageConversionEvent', payload)
          .then(() => {
            const win = window.open(url, '_self')
            win.focus()
          })
      }
    }
  },
  computed: {
    ...mapGetters([
      'currentAndroidStore',
      'attributionParams'
    ])
  },
  mounted () {

  }
};
</script>

<style lang="css">
.install__button {
  height: 2rem!important;
  color: white!important;
  text-transform: unset!important;
  letter-spacing: unset!important;
}
</style>
