<template>
  <v-row no-gutters justify="center">
    <v-col cols="11">
      <v-row no-gutters>
        <v-col cols="11">
          <span class="rating__title">{{ currentAndroidStore.translation.ratings_title }}</span>
        </v-col>
        <!-- <v-col cols="grow">
          <v-icon class="rating__info ml-3" small color="grey">info outline</v-icon>
        </v-col> -->
        <v-col cols="1">
          <v-icon color="grey darken-2">arrow_forward</v-icon>
        </v-col>
      </v-row>
      <v-row no-gutters class="mt-3">
        <v-col cols="3">
          <div class="rating__rating_row">
            <span class="rating__rating">{{ currentAndroidStore.note }}</span>
          </div>
          <div class="rating__rating_row mt-1">
            <v-rating class="rating__star" color="#00885e" background-color="#00885e" half-increments v-model="currentAndroidStoreNote" dense></v-rating>
          </div>
          <div class="rating__rating_row">
            <span class="rating__rating_count">{{ currentAndroidStore.ratings_count }}</span>
          </div>
        </v-col>
        <v-col cols="1" />
        <v-col cols="8">
          <v-row v-for="i in 5" :key="i" no-gutters align="center" class="rating__progress_row">
            <v-col cols="1">
              <span class="rating__progress_number">{{ 6-i }}</span>
            </v-col>
            <v-col cols="11">
              <v-progress-linear class="rating__progress" color="#00885e" background-color="blue-grey lighten-5" :value="currentAndroidStore.ratings_details[6-i] * 100"></v-progress-linear>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  components: {

  },
  data: () => ({

  }),
  methods: {

  },
  computed: {
    ...mapGetters([
      'currentAndroidStore'
    ]),
    currentAndroidStoreNote () {
      return parseFloat(this.currentAndroidStore.note)
    }
  },
  mounted () {

  }
};
</script>

<style lang="css">
.rating__title {
  font-size: 1rem;
  font-weight: 500;
}

.rating__info {
  font-size: 0.8rem!important;
}

.rating__rating_row {
  line-height: 1;
}

.rating__rating {
  font-size: 3rem;
  font-weight: 500;
}

.rating__rating_count {
  color: #424242;
  font-size: 0.8rem;
  font-weight: 400;
}

.rating__star button {
  color: #00885e!important;
  font-size: 13px!important;
  padding: 0!important;
}

.rating__progress_row {
  height: 1rem;
}

.rating__progress_number {
  font-size: 0.7rem;
  font-weight: 400;
}

.rating__progress {
  height: 8px!important;
  border-radius: 4px;
}

</style>
