<template>
  <v-row class="stores-buttons-container" no-gutters justify="center">
    <v-col cols="auto">
      <v-btn text>
        <v-img eager width="135" @click="sendEventToPixel('appstore')" :src="'/static/img/download_appstore.svg'" />
      </v-btn>
    </v-col>
    <v-col cols="auto">
      <v-btn text>
        <v-img eager width="150" @click="sendEventToPixel('playstore')" :src="'/static/img/download_playstore.svg'" />
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex';

const uuidv4 = require('uuid/v4')

export default {
  components: {

  },
  data: () => ({

  }),
  methods: {
    sendEventToPixel (source) {
      const eventId = uuidv4()

      // Send to MWM backend
      this.$store.dispatch('sendEvent', { event_type: 'on_click', value: JSON.stringify({ placement: 'bottom_cta', subplacement: source }) })

      const payload = {
        source: this.currentLanding.id,
        data: [
          {
            event_name: 'OnClick',
            event_id: eventId,
            custom_data: {
              placement: 'bottom_cta',
              subplacement: source
            }
          }
        ]
      }
      const params = this.attributionParams
      const pid = params.utm_source
      const campaignId = params.utm_campaign
      const adsetId = params.adset
      const adId = params.ad
      const url = `${this.currentLanding.attribution_url}?pid=${pid}&c=${campaignId}&af_c_id=${campaignId}&af_adset_id=${adsetId}&af_ad_id=${adId}`


      if (this.currentLanding.pixel_id.includes('AW-')) {
        // SEND TO GOOGLE ADS
        window.gtag('event', 'conversion', {
          send_to: 'AW-377188406/bm7wCI6OsowCELbg7bMB',
          transaction_id: eventId
        });

        const win = window.open(url, '_self')
        win.focus()
      } else {
        // SEND TO FACEBOOK + HANDLE CONVERSION
        window.fbq('trackSingleCustom', this.currentLanding.pixel_id, 'OnClick', { placement: 'bottom_cta', subplacement: source }, { eventID: eventId });

        this.$store.dispatch('sendLandingConversionEvent', payload)
          .then(() => {
            const win = window.open(url, '_self')
            win.focus()
          })
      }
    }
  },
  computed: {
    ...mapGetters([
      'attributionParams',
      'currentLanding'
    ])
  },
  mounted () {

  }
};
</script>

<style scoped lang="css">
  .stores-buttons-container {
    height: 30vw;
    margin-top: 20vw!important;
  }
</style>
