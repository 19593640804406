<template>
  <div v-if="currentAndroidStore != null" :style="backStyle">
    <icon-title class="mt-4" />
    <meta-data class="android__metadata" />
    <install-btn class="android__install" />
    <screenshot-carousel class="android__screenshots" />
    <about-app class="android__about" />
    <rating-reviews class="android__rating" />
    <rating-comments class="android__reviews" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import IconTitle from './android/IconTitle.vue'
import MetaData from './android/MetaData.vue'
import Install from './android/Install.vue'
import ScreenshotsCarousel from './android/ScreenshotsCarousel.vue'
import About from './android/About.vue'
import Rating from './android/Rating.vue'
import Comments from './android/Comments.vue'

export default {
  components: {
    'icon-title': IconTitle,
    'meta-data': MetaData,
    'install-btn': Install,
    'screenshot-carousel': ScreenshotsCarousel,
    'about-app': About,
    'rating-reviews': Rating,
    'rating-comments': Comments,
  },
  data: () => ({
    backStyle: 'height: 100%; background-color: white;'
  }),
  methods: {

  },
  computed: {
    ...mapGetters([
      'currentAndroidStore'
    ])
  },
  mounted () {
    this.$vuetify.application.framework.theme.dark = false
  }
};
</script>

<style lang="css">

.android__metadata {
  margin-top: 1.5rem!important;
}

.android__install {
  margin-top: 1.5rem!important;
}

.android__screenshots {
  margin-top: 1.5rem!important;
}

.android__about {
  margin-top: 2rem!important;
}

.android__rating {
  margin-top: 1.5rem!important;
}

.android__reviews {
  margin-top: 2rem!important;
}

</style>
