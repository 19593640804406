<template>
  <v-app :style="{background: $vuetify.theme.themes[theme].background}">
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex';

import CssHolder from '@/mixins/CssHolder.vue';
import SizeHolder from '@/mixins/SizeHolder.vue';

const uuidv4 = require('uuid/v4')

export default {
  mixins: [CssHolder, SizeHolder],
  data: () => ({

  }),
  methods: {

  },
  computed: {
    ...mapGetters([
      'currentInstallationId',
      'currentPageId',
    ]),
    theme () {
      return (this.$vuetify.theme.dark) ? 'dark' : 'light'
    }
  },
  mounted () {
    if (this.currentInstallationId === '') {
      this.$store.commit('setInstallationId', uuidv4())
    }
    if (this.currentPageId === '') {
      this.$store.commit('setPageId', this.$route.params.pageId)
    }
    this.$store.commit('setAttributionParams', this.$route.query)
    // https://app-mwm.com/v/b3406d08?utm_source=site_source_name&utm_campaign=campaign_id&adset=adset_id&ad=ad_id

    let dispatchMethod = ''
    let dispatchObj = null
    if (Object.prototype.hasOwnProperty.call(this.$route.params, 'pageId')) {
      dispatchMethod = 'fetchPage'
      dispatchObj = { page_id: this.$route.params.pageId }
    } else if (Object.prototype.hasOwnProperty.call(this.$route.params, 'landingId')) {
      dispatchMethod = 'fetchLanding'
      dispatchObj = { landing_id: this.$route.params.landingId }
    } else if (Object.prototype.hasOwnProperty.call(this.$route.params, 'androidPageId')) {
      dispatchMethod = 'fetchAndroidPage'
      dispatchObj = { android_page_id: this.$route.params.androidPageId }
    }

    this.$store.dispatch(dispatchMethod, dispatchObj)
      .then((response) => {
        if (response.data.pixel_id === '') { return }

        if (response.data.pixel_id.includes('AW-')) {
          window.gtag('config', response.data.pixel_id);
        } else {
          window.fbq('init', response.data.pixel_id);
          window.fbq('trackSingleCustom', response.data.pixel_id, 'PageView', {});
        }

        this.$store.dispatch('sendEvent', { event_type: 'page_view', value: '' })
      })
  }
};
</script>

<style lang="scss">
* {
  // font-family: -apple-system,BlinkMacSystemFont,sans-serif;
  // font-family: 'Roboto', sans-serif!important;
}

* p {
  margin-bottom: 0!important;
}
</style>
