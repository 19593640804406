<template>
  <div v-if="currentStore != null">
    <top-menu />
    <icon-title />
    <v-divider class="mt-5 ml-5" />
    <meta-data />
    <screenshot-carousel class="mt-5 ml-5" />
    <v-divider class="mt-3 mx-5" />
    <device-iphone class="ml-5" />
    <app-developer class="ml-5" />
    <v-divider class="mt-5 mx-5" />
    <ratings-reviews />
    <reviews-carousel class="ml-5" />
    <v-divider class="mt-3 mx-5" />
    <whats-new />
    <v-divider class="mx-5" />
    <app-information />
    <bot-footer v-view="visibilityChanged" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import TopMenu from './store/TopMenu.vue'
import IconTitle from './store/IconTitle.vue'
import MetaData from './store/MetaData.vue'
import ScreenshotsCarousel from './store/ScreenshotsCarousel.vue'
import DeviceIphone from './store/DeviceIphone.vue'
import AppDeveloper from './store/AppDeveloper.vue'
import Information from './store/Information.vue'
import RatingsReviews from './store/RatingsReviews.vue'
import ReviewsCarousel from './store/ReviewsCarousel.vue'
import WhatsNew from './store/WhatsNew.vue'
import Footer from './store/Footer.vue'

export default {
  components: {
    'top-menu': TopMenu,
    'icon-title': IconTitle,
    'meta-data': MetaData,
    'screenshot-carousel': ScreenshotsCarousel,
    'device-iphone': DeviceIphone,
    'app-developer': AppDeveloper,
    'app-information': Information,
    'ratings-reviews': RatingsReviews,
    'reviews-carousel': ReviewsCarousel,
    'whats-new': WhatsNew,
    'bot-footer': Footer,
  },
  data: () => ({
    scrolled25StorageKey: 'scrolled25StorageKey',
    scrolled50StorageKey: 'scrolled50StorageKey',
    scrolled75StorageKey: 'scrolled75StorageKey',
    scrolled100StorageKey: 'scrolled100StorageKey',
  }),
  methods: {
    visibilityChanged (e) {
      // console.log('type', e.type, 'percentTop', e.percentTop, 'scrollPercent', e.scrollPercent);

      // console.log('type', e.type) // 'enter', 'exit', 'progress'
      // // console.log('percentInView', e.percentInView) // 0..1 how much element overlap the viewport
      // console.log('percentTop', e.percentTop) // 0..1 position of element at viewport 0 - above , 1 - below
      // // console.log('percentCenter', e.percentCenter) // 0..1 position the center of element at viewport 0 - center at viewport top, 1 - center at viewport bottom
      // console.log('scrollPercent', e.scrollPercent) // 0..1 current scroll position of page
      // // console.log('scrollValue', e.scrollValue) // 0..1 last scroll value (change of page scroll offset)
      // // console.log('target', e.target.rect) // element.getBoundingClientRect() result

      this.handleScroll(e.scrollPercent)
    },
    handleScroll (scrollPercent) {
      this.sendEventForScrollTargetIfNeeded(scrollPercent, 0.25, '25', this.scrolled25StorageKey)
      this.sendEventForScrollTargetIfNeeded(scrollPercent, 0.50, '50', this.scrolled50StorageKey)
      this.sendEventForScrollTargetIfNeeded(scrollPercent, 0.75, '75', this.scrolled75StorageKey)
      this.sendEventForScrollTargetIfNeeded(scrollPercent, 1, '100', this.scrolled100StorageKey)
    },
    sendEventForScrollTargetIfNeeded (scrollPercent, trgtPercent, trgt, key) {
      if (scrollPercent >= trgtPercent) {
        if (!this.$session.exists(key)) {
          this.$session.set(key, true);
          this.sendEventForScrollTarget(trgt)
        }
      }
    },
    sendEventForScrollTarget (trgt) {
      this.$store.dispatch('sendEvent', { event_type: 'scroll_target_reached', value: JSON.stringify({ target: trgt }) })
    }
  },
  computed: {
    ...mapGetters([
      'currentStore'
    ])
  },
  mounted () {

  },
};
</script>

<style lang="css">

</style>
