<template>
  <section class="app__reviews">
    <div class="app__reviews-top">
      <h2 data-l10n-id="ratings_and_reviews">{{ currentStore.translation.ratings_reviews_title }}</h2>
      <p data-l10n-id="see_all">{{ currentStore.translation.ratings_reviews_see }}</p>
    </div>
    <div class="app__reviews-rating">
      <div class="app__reviews-numbers">
        <p class="app__reviews-value">{{ currentStore.note }}</p>
        <p class="app__reviews-outOf" data-l10n-id="out_of_5">{{ currentStore.translation.ratings_reviews_outof }}</p>
      </div>
      <div class="app__reviews-charts">
        <div class="app__reviews-progress">
          <div class="app__reviews-progress-inside app__reviews-five">
            <div class="app__reviews-progress-percents" style="width: 74%"></div>
          </div>
          <div class="app__reviews-progress-stars">
            <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 11 11">
              <linearGradient id="lg0-5-1543240969155">
                <stop offset="0%" stop-opacity="1" stop-color="#909095"></stop>
                <stop offset="500%" stop-opacity="1" stop-color="#909095"></stop>
                <stop offset="500%" stop-opacity="0" stop-color="#909095"></stop>
                <stop offset="100%" stop-opacity="0" stop-color="#909095"></stop>
              </linearGradient>
              <path id="stroke" fill="url(#lg0-5-1543240969155)" stroke="#909095" stroke-width="0.55" d="M8.3634879,9.7242952 L7.58494933,6.46048334 L10.1332207,4.27761756 L6.78856955,4.0094784 L5.5,0.911385646 L4.21143045,4.0094784 L0.866779258,4.27761756 L3.41505067,6.46048334 L2.6365121,9.7242952 L5.5,7.9752877 L8.3634879,9.7242952 Z"></path>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 11 11">
              <linearGradient id="lg0-5-1543240969155">
                <stop offset="0%" stop-opacity="1" stop-color="#909095"></stop>
                <stop offset="500%" stop-opacity="1" stop-color="#909095"></stop>
                <stop offset="500%" stop-opacity="0" stop-color="#909095"></stop>
                <stop offset="100%" stop-opacity="0" stop-color="#909095"></stop>
              </linearGradient>
              <path id="stroke" fill="url(#lg0-5-1543240969155)" stroke="#909095" stroke-width="0.55" d="M8.3634879,9.7242952 L7.58494933,6.46048334 L10.1332207,4.27761756 L6.78856955,4.0094784 L5.5,0.911385646 L4.21143045,4.0094784 L0.866779258,4.27761756 L3.41505067,6.46048334 L2.6365121,9.7242952 L5.5,7.9752877 L8.3634879,9.7242952 Z"></path>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 11 11">
              <linearGradient id="lg0-5-1543240969155">
                <stop offset="0%" stop-opacity="1" stop-color="#909095"></stop>
                <stop offset="500%" stop-opacity="1" stop-color="#909095"></stop>
                <stop offset="500%" stop-opacity="0" stop-color="#909095"></stop>
                <stop offset="100%" stop-opacity="0" stop-color="#909095"></stop>
              </linearGradient>
              <path id="stroke" fill="url(#lg0-5-1543240969155)" stroke="#909095" stroke-width="0.55" d="M8.3634879,9.7242952 L7.58494933,6.46048334 L10.1332207,4.27761756 L6.78856955,4.0094784 L5.5,0.911385646 L4.21143045,4.0094784 L0.866779258,4.27761756 L3.41505067,6.46048334 L2.6365121,9.7242952 L5.5,7.9752877 L8.3634879,9.7242952 Z"></path>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 11 11">
              <linearGradient id="lg0-5-1543240969155">
                <stop offset="0%" stop-opacity="1" stop-color="#909095"></stop>
                <stop offset="500%" stop-opacity="1" stop-color="#909095"></stop>
                <stop offset="500%" stop-opacity="0" stop-color="#909095"></stop>
                <stop offset="100%" stop-opacity="0" stop-color="#909095"></stop>
              </linearGradient>
              <path id="stroke" fill="url(#lg0-5-1543240969155)" stroke="#909095" stroke-width="0.55" d="M8.3634879,9.7242952 L7.58494933,6.46048334 L10.1332207,4.27761756 L6.78856955,4.0094784 L5.5,0.911385646 L4.21143045,4.0094784 L0.866779258,4.27761756 L3.41505067,6.46048334 L2.6365121,9.7242952 L5.5,7.9752877 L8.3634879,9.7242952 Z"></path>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 11 11">
              <linearGradient id="lg0-5-1543240969155">
                <stop offset="0%" stop-opacity="1" stop-color="#909095"></stop>
                <stop offset="500%" stop-opacity="1" stop-color="#909095"></stop>
                <stop offset="500%" stop-opacity="0" stop-color="#909095"></stop>
                <stop offset="100%" stop-opacity="0" stop-color="#909095"></stop>
              </linearGradient>
              <path id="stroke" fill="url(#lg0-5-1543240969155)" stroke="#909095" stroke-width="0.55" d="M8.3634879,9.7242952 L7.58494933,6.46048334 L10.1332207,4.27761756 L6.78856955,4.0094784 L5.5,0.911385646 L4.21143045,4.0094784 L0.866779258,4.27761756 L3.41505067,6.46048334 L2.6365121,9.7242952 L5.5,7.9752877 L8.3634879,9.7242952 Z"></path>
            </svg>
          </div>
        </div>
        <div class="app__reviews-progress">
          <div class="app__reviews-progress-inside app__reviews-four">
            <div class="app__reviews-progress-percents" style="width: 10%"></div>
          </div>
          <div class="app__reviews-progress-stars">
            <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 11 11">
              <linearGradient id="lg0-5-1543240969155">
                <stop offset="0%" stop-opacity="1" stop-color="#909095"></stop>
                <stop offset="500%" stop-opacity="1" stop-color="#909095"></stop>
                <stop offset="500%" stop-opacity="0" stop-color="#909095"></stop>
                <stop offset="100%" stop-opacity="0" stop-color="#909095"></stop>
              </linearGradient>
              <path id="stroke" fill="url(#lg0-5-1543240969155)" stroke="#909095" stroke-width="0.55" d="M8.3634879,9.7242952 L7.58494933,6.46048334 L10.1332207,4.27761756 L6.78856955,4.0094784 L5.5,0.911385646 L4.21143045,4.0094784 L0.866779258,4.27761756 L3.41505067,6.46048334 L2.6365121,9.7242952 L5.5,7.9752877 L8.3634879,9.7242952 Z"></path>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 11 11">
              <linearGradient id="lg0-5-1543240969155">
                <stop offset="0%" stop-opacity="1" stop-color="#909095"></stop>
                <stop offset="500%" stop-opacity="1" stop-color="#909095"></stop>
                <stop offset="500%" stop-opacity="0" stop-color="#909095"></stop>
                <stop offset="100%" stop-opacity="0" stop-color="#909095"></stop>
              </linearGradient>
              <path id="stroke" fill="url(#lg0-5-1543240969155)" stroke="#909095" stroke-width="0.55" d="M8.3634879,9.7242952 L7.58494933,6.46048334 L10.1332207,4.27761756 L6.78856955,4.0094784 L5.5,0.911385646 L4.21143045,4.0094784 L0.866779258,4.27761756 L3.41505067,6.46048334 L2.6365121,9.7242952 L5.5,7.9752877 L8.3634879,9.7242952 Z"></path>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 11 11">
              <linearGradient id="lg0-5-1543240969155">
                <stop offset="0%" stop-opacity="1" stop-color="#909095"></stop>
                <stop offset="500%" stop-opacity="1" stop-color="#909095"></stop>
                <stop offset="500%" stop-opacity="0" stop-color="#909095"></stop>
                <stop offset="100%" stop-opacity="0" stop-color="#909095"></stop>
              </linearGradient>
              <path id="stroke" fill="url(#lg0-5-1543240969155)" stroke="#909095" stroke-width="0.55" d="M8.3634879,9.7242952 L7.58494933,6.46048334 L10.1332207,4.27761756 L6.78856955,4.0094784 L5.5,0.911385646 L4.21143045,4.0094784 L0.866779258,4.27761756 L3.41505067,6.46048334 L2.6365121,9.7242952 L5.5,7.9752877 L8.3634879,9.7242952 Z"></path>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 11 11">
              <linearGradient id="lg0-5-1543240969155">
                <stop offset="0%" stop-opacity="1" stop-color="#909095"></stop>
                <stop offset="500%" stop-opacity="1" stop-color="#909095"></stop>
                <stop offset="500%" stop-opacity="0" stop-color="#909095"></stop>
                <stop offset="100%" stop-opacity="0" stop-color="#909095"></stop>
              </linearGradient>
              <path id="stroke" fill="url(#lg0-5-1543240969155)" stroke="#909095" stroke-width="0.55" d="M8.3634879,9.7242952 L7.58494933,6.46048334 L10.1332207,4.27761756 L6.78856955,4.0094784 L5.5,0.911385646 L4.21143045,4.0094784 L0.866779258,4.27761756 L3.41505067,6.46048334 L2.6365121,9.7242952 L5.5,7.9752877 L8.3634879,9.7242952 Z"></path>
            </svg>
          </div>
        </div>
        <div class="app__reviews-progress">
          <div class="app__reviews-progress-inside app__reviews-three">
            <div class="app__reviews-progress-percents" style="width: 7%"></div>
          </div>
          <div class="app__reviews-progress-stars">
            <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 11 11">
              <linearGradient id="lg0-5-1543240969155">
                <stop offset="0%" stop-opacity="1" stop-color="#909095"></stop>
                <stop offset="500%" stop-opacity="1" stop-color="#909095"></stop>
                <stop offset="500%" stop-opacity="0" stop-color="#909095"></stop>
                <stop offset="100%" stop-opacity="0" stop-color="#909095"></stop>
              </linearGradient>
              <path id="stroke" fill="url(#lg0-5-1543240969155)" stroke="#909095" stroke-width="0.55" d="M8.3634879,9.7242952 L7.58494933,6.46048334 L10.1332207,4.27761756 L6.78856955,4.0094784 L5.5,0.911385646 L4.21143045,4.0094784 L0.866779258,4.27761756 L3.41505067,6.46048334 L2.6365121,9.7242952 L5.5,7.9752877 L8.3634879,9.7242952 Z"></path>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 11 11">
              <linearGradient id="lg0-5-1543240969155">
                <stop offset="0%" stop-opacity="1" stop-color="#909095"></stop>
                <stop offset="500%" stop-opacity="1" stop-color="#909095"></stop>
                <stop offset="500%" stop-opacity="0" stop-color="#909095"></stop>
                <stop offset="100%" stop-opacity="0" stop-color="#909095"></stop>
              </linearGradient>
              <path id="stroke" fill="url(#lg0-5-1543240969155)" stroke="#909095" stroke-width="0.55" d="M8.3634879,9.7242952 L7.58494933,6.46048334 L10.1332207,4.27761756 L6.78856955,4.0094784 L5.5,0.911385646 L4.21143045,4.0094784 L0.866779258,4.27761756 L3.41505067,6.46048334 L2.6365121,9.7242952 L5.5,7.9752877 L8.3634879,9.7242952 Z"></path>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 11 11">
              <linearGradient id="lg0-5-1543240969155">
                <stop offset="0%" stop-opacity="1" stop-color="#909095"></stop>
                <stop offset="500%" stop-opacity="1" stop-color="#909095"></stop>
                <stop offset="500%" stop-opacity="0" stop-color="#909095"></stop>
                <stop offset="100%" stop-opacity="0" stop-color="#909095"></stop>
              </linearGradient>
              <path id="stroke" fill="url(#lg0-5-1543240969155)" stroke="#909095" stroke-width="0.55" d="M8.3634879,9.7242952 L7.58494933,6.46048334 L10.1332207,4.27761756 L6.78856955,4.0094784 L5.5,0.911385646 L4.21143045,4.0094784 L0.866779258,4.27761756 L3.41505067,6.46048334 L2.6365121,9.7242952 L5.5,7.9752877 L8.3634879,9.7242952 Z"></path>
            </svg>
          </div>
        </div>
        <div class="app__reviews-progress">
          <div class="app__reviews-progress-inside app__reviews-two">
            <div class="app__reviews-progress-percents" style="width: 3%"></div>
          </div>
          <div class="app__reviews-progress-stars">
            <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 11 11">
              <linearGradient id="lg0-5-1543240969155">
                <stop offset="0%" stop-opacity="1" stop-color="#909095"></stop>
                <stop offset="500%" stop-opacity="1" stop-color="#909095"></stop>
                <stop offset="500%" stop-opacity="0" stop-color="#909095"></stop>
                <stop offset="100%" stop-opacity="0" stop-color="#909095"></stop>
              </linearGradient>
              <path id="stroke" fill="url(#lg0-5-1543240969155)" stroke="#909095" stroke-width="0.55" d="M8.3634879,9.7242952 L7.58494933,6.46048334 L10.1332207,4.27761756 L6.78856955,4.0094784 L5.5,0.911385646 L4.21143045,4.0094784 L0.866779258,4.27761756 L3.41505067,6.46048334 L2.6365121,9.7242952 L5.5,7.9752877 L8.3634879,9.7242952 Z"></path>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 11 11">
              <linearGradient id="lg0-5-1543240969155">
                <stop offset="0%" stop-opacity="1" stop-color="#909095"></stop>
                <stop offset="500%" stop-opacity="1" stop-color="#909095"></stop>
                <stop offset="500%" stop-opacity="0" stop-color="#909095"></stop>
                <stop offset="100%" stop-opacity="0" stop-color="#909095"></stop>
              </linearGradient>
              <path id="stroke" fill="url(#lg0-5-1543240969155)" stroke="#909095" stroke-width="0.55" d="M8.3634879,9.7242952 L7.58494933,6.46048334 L10.1332207,4.27761756 L6.78856955,4.0094784 L5.5,0.911385646 L4.21143045,4.0094784 L0.866779258,4.27761756 L3.41505067,6.46048334 L2.6365121,9.7242952 L5.5,7.9752877 L8.3634879,9.7242952 Z"></path>
            </svg>
          </div>
        </div>
        <div class="app__reviews-progress">
          <div class="app__reviews-progress-inside app__reviews-one">
            <div class="app__reviews-progress-percents" style="width: 6%"></div>
          </div>
          <div class="app__reviews-progress-stars">
            <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 11 11">
              <linearGradient id="lg0-5-1543240969155">
                <stop offset="0%" stop-opacity="1" stop-color="#909095"></stop>
                <stop offset="500%" stop-opacity="1" stop-color="#909095"></stop>
                <stop offset="500%" stop-opacity="0" stop-color="#909095"></stop>
                <stop offset="100%" stop-opacity="0" stop-color="#909095"></stop>
              </linearGradient>
              <path id="stroke" fill="url(#lg0-5-1543240969155)" stroke="#909095" stroke-width="0.55" d="M8.3634879,9.7242952 L7.58494933,6.46048334 L10.1332207,4.27761756 L6.78856955,4.0094784 L5.5,0.911385646 L4.21143045,4.0094784 L0.866779258,4.27761756 L3.41505067,6.46048334 L2.6365121,9.7242952 L5.5,7.9752877 L8.3634879,9.7242952 Z"></path>
            </svg>
          </div>
        </div>
        <p class="app__reviews-values"><span class="app__reviews-values-number">{{ currentStore.ratings }}</span> <span data-l10n-id="ratings">{{ currentStore.translation.ratings_reviews_ratings }}</span></p>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  components: {

  },
  data: () => ({

  }),
  methods: {

  },
  computed: {
    ...mapGetters([
      'currentStore'
    ]),
  },
  mounted () {

  }
};
</script>

<style lang="css">

@media (prefers-color-scheme: dark) {
  .app__reviews-value {
    color: #ebebf5!important;
  }

  .app__reviews-progress-inside {
    background: #2a2a2c!important;
  }
}

.app__reviews {
	padding: 2vw 5vw 3vw
}

.app__reviews-rating {
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-moz-box-orient: horizontal;
	-moz-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row
}

.app__reviews h2 {
	font-weight: 700;
	font-size: 5.5vw
}

.app__reviews-value {
	font-size: 16vw;
	color: #49494d;
	font-weight: 600;
	line-height: .7
}

.app__reviews-outOf {
	text-align: center;
	font-size: 3.6vw;
	color: gray;
	padding-top: 2.3vw;
	font-weight: 700
}

.app__reviews-progress,
.app__reviews-progress-stars {
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-moz-box-orient: horizontal
}

.app__reviews-progress {
	-webkit-box-align: center;
	-webkit-align-items: center;
	-moz-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	margin-bottom: .2vw;
	-webkit-box-direction: reverse;
	-webkit-flex-direction: row-reverse;
	-moz-box-direction: reverse;
	-ms-flex-direction: row-reverse;
	flex-direction: row-reverse
}

.app__reviews-progress-stars {
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-moz-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-moz-box-pack: end;
	-ms-flex-pack: end;
	justify-content: flex-end
}

.app__reviews-progress-stars svg {
	width: 2vw;
	height: 2vw;
	margin-right: .2vw
}

.app__reviews-progress-inside {
	height: .8vw;
	background: #efefef;
	width: 44vw;
	margin-left: 2vw;
	border-radius: 50px
}

.app__reviews-progress-percents {
	background: #9d9da2;
	height: .8vw;
	border-radius: 50px
}

#closer_look video,
.app__reviews-charts {
	width: 100%
}

.app__reviews-values {
	text-align: right;
	font-size: 3.6vw;
	color: #909090;
	padding-top: 1.3vw
}

.app__reviews-top {
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-moz-box-orient: horizontal;
	-moz-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-moz-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-moz-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	padding-bottom: 5vw
}

.app__reviews-top p {
	font-size: 4.1vw;
  font-weight: 600;
	color: #4788ee
}

.app__new {
	padding: 2.7vw 5vw 7.5vw
}

.app__new-caption {
	font-weight: 700;
	font-size: 5.5vw;
	line-height: 1.2
}

.app__new-top,
.app__new-version-date {
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-moz-box-orient: horizontal;
	-moz-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-moz-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between
}

.app__new-top {
	-webkit-box-align: center;
	-webkit-align-items: center;
	-moz-box-align: center;
	-ms-flex-align: center;
	align-items: center
}

.app__new-verisonHistory {
	font-size: 4vw;
	color: #4788ee
}

.app__new-date,
.app__new-version {
	padding: 2.8vw 0;
	color: #939396;
	font-size: 3.7vw
}

.app__new-description {
	position: relative
}

.app__new-description p {
	color: #1a1a1a;
	font-size: 3.55vw;
	max-height: 4em;
	overflow: hidden;
	line-height: 1.4
}

.app__new-more {
	color: #267ff9;
	text-decoration: none;
	position: absolute;
	bottom: -2px;
	right: 0;
	background: #fff;
	font-size: 3.55vw;
	padding-left: 5vw
}

.app__new-more:before {
	content: "";
	display: block;
	position: absolute;
	left: -30px;
	top: 0;
	bottom: 0;
	width: 30px;
	background: -o-linear-gradient(left, rgba(255, 255, 255, 0), #fff);
	background: -webkit-gradient(linear, left top, right top, from(rgba(255, 255, 255, 0)), to(#fff));
	background: linear-gradient(90deg, rgba(255, 255, 255, 0), #fff)
}
</style>
