<template>
  <div>
    <mwm-slick
      ref="slick"
      :options="slickOptions"
    >
      <div v-if="currentStore.hasOwnProperty('video') && currentStore.video !== ''" class="video-container" @click="switchMute">
        <div class="video-controls" id='controls'>
          <v-chip label color="#cccccc33">
            <v-btn label icon :ripple="false">
              <v-icon v-if="player !== null && isPlayerMuted">volume_off</v-icon>
              <v-icon v-else-if="player !== null && !isPlayerMuted">volume_up</v-icon>
            </v-btn>
          </v-chip>
        </div>
        <video autoplay loop muted playsinline id="video-element" class="mwm-video">
          <source :src="currentStore.video" type="video/mp4">
        Your browser does not support the video tag.
        </video>
      </div>
      <div v-for="screen in currentStore.screenshots" :key="screen">
        <v-img class="mwm-image" eager contains :src="screen" />
      </div>
    </mwm-slick>
    <div class="app__device-iphone">
      <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAeCAYAAAAy2w7YAAAABGdBTUEAALGPC/xhBQAAAQ9JREFUSA3tljEKwkAQRXeSFQvFG2gnir2FWFgFDyERPIe23kIjEfEWNt4iYKFgZ20j2R1nDUm1kBFiIztNZoef/zcvzUAcH0dpqnZCYE9UXpAC4Mn3mzOpVBqR/w9CzK1RIoqpUs+lafpmBOAHAOJm+qoKEeeIemVoydy0VtPXMAwv+bmKZxTtH5kPgFeFIcfDBXEoWTUOnRULZ+jQcShZNQ6dFQtn6NBxKFk1Dp0VC2f4f+iKLej1wsl2e+hyMPA1OKBV6yMvgui0EUKXetDu96BdsEXCepmYdEV99Y9MCEC743kQFA6sBpGCIGFpSZR9yX2oNYy572Q6SKSU/uKLJb+uNZ5z7uVh+ZLfWL8BGthMXGnmBI8AAAAASUVORK5CYII=" alt="">
      <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAXCAYAAAAcP/9qAAAABGdBTUEAALGPC/xhBQAAA0NJREFUSA21Vk1oU0EQ3t3X2tqWFGyKCEVzUA9WWo/1INiD2lZCDoUgvMSSVHuwF7GKBelVsFKoBxGFJpKkkcYfkCrUiwgigoLUQwsKQsUfEHLpL2n6sus3yXuPNi1B4sscMrM7P9/M7tuZcLYDxePxg9ksCzCmujnnHpg0K6X4DqbbtmCv4JdmjC9wzmaqqthkMBj8Umy4JVgsFqs3DH5bKXYRzlXFxuWskUgOSUfc7sYhn8+3bMWwgROJRMv6unwJRRspke03FDmlaeyTlFoa/tJyKsU5F4gpm6Xkx5SSftgeJnskMF9TI3p0Xf+eX9NPKpVqWF7OvIPYBoMs+FBDQ809v9+fI325hEpFNJq4AP9xyLsJvKnJ1UGV549zZWV9FMo8KCo9EwoF35QLttkPQHRKDyKR2Gfw1wA/kk4vjkEe4NHo5CEp5TzdqRD8MkDvbHZ2So5E4iG6aySTq65mrQKgOoGi0gWPp+WuU0DFcUKhwENgzAFcMwymC4B2F4z4k87OTqPYwak1KsUzE48oHl5Nl8CGhxZCsFnilSQhlImhPFSxywSz31ilwHM5bZFio2IXgPmPwoIfqBSgFVcIYx/JuOs/AuhfaYFLP068koSmcpLiozHNC9CUCdabTCb3mrLjDE2qEZDnKDCe7XPhdrueovg0Kt6VyRj3we026iS62aT2EBbecVJ4vd41AAwSCI7dhxY3huw0J0EnJuIjKGiAYuJ+bwQCgSW7OijRPNSlgpK/RU89u3malJMIpt1RNItbKKjHBI2Ew+f7SbZHX39/cBDgqwC/huxOoKfSZJkgI4ui0fgpKVkXsi5xItAy6YZP+8YGa7V80SfG6+trr1prG5g2oFxFYKI1jLBXeQk/hcz5TSmVl/ZQQQnaqkSDwoAQ10Mh3Y5HzjZwLJbabxiZYdrEVzeKe/iJ++7ATL2CzHsBhzefp/e4qd+mvI2hXhyYSiPGAoqYCYeDNJm2kQ0MUB2V1MKCPjYN02QWA6Td8kDAOaQ0HA4HXlh7/8NtYIAumYHqcKQjVlAc1UdUOIajegz5n/6FWL6luP1V46+PK5uVKYCcRhK/APJM0/hkX5/+oVQAx3TT09N1jgUrEegvu/1H9u0NcwAAAAAASUVORK5CYII=" alt="">
      <p>{{ currentStore.translation.screenshot_carousel_offers }}</p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import Slick from 'vue-slick';
import 'slick-carousel/slick/slick.css';

export default {
  components: {
    'mwm-slick': Slick
  },
  data: () => ({
    player: null,
    isPlayerMuted: true
  }),
  methods: {
    switchMute () {
      if (this.player === null) { return }
      if (this.player.muted) {
        this.player.muted = false;
        this.isPlayerMuted = false
      } else {
        this.player.muted = true;
        this.isPlayerMuted = true
      }
    }
  },
  computed: {
    ...mapGetters([
      'currentStore'
    ]),
    slickOptions () {
      const slidesToShowCount = this.currentStore.screenshots_orientation === 'vertical' ? 1.7 : 1.1
      return {
        slidesToShow: slidesToShowCount,
        arrows: false,
        infinite: false
      }
    }
  },
  mounted () {
    this.player = document.getElementById('video-element');
  }
};
</script>

<style lang="css">
.mwm-image .v-image__image {
  border-radius: 15px;
  width: 98%;
}

.video-container {
  position: relative;
}

.video-controls {
  position: absolute;
  bottom: 15px;
  right: 15px;
}

.mwm-video {
  width: 98%;
  height: 10.3rem;
  display: flex;
}

.app__device-iphone {
  padding-top: 1vw;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -moz-box-orient: horizontal;
  -moz-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-right: 5vw;
  position: relative;
}

.app__device-iphone img:first-child {
  width: 2.5vw;
  height: 3.7vw;
  margin-right: 2.5vw;
  display: block;
}

.app__device-iphone img:nth-child(2) {
  width: 4.5vw;
  height: 3.8vw;
}

.app__device-iphone p {
  color: #9d9da2;
  font-weight: 700!important;
  font-size: 3.4vw;
  margin-left: 2.5vw;
  margin-bottom: 0!important;
  font-weight: 600;
}

.app__device-iphone:after {
  content: "";
  border-right: 2px solid #9d9da2;
  border-top: 2px solid #9d9da2;
  -webkit-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  transform: rotate(135deg);
  width: 7px;
  height: 7px;
  position: absolute;
  top: 40%;
  right: 5vw;
  margin-top: 1vw;
  display: block;
}
</style>
