<template>
  <v-row no-gutters justify="center">
    <v-col cols="12">

    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  components: {

  },
  data: () => ({

  }),
  methods: {

  },
  computed: {
    ...mapGetters([

    ]),
  },
  mounted () {

  }
};
</script>

<style scoped lang="scss">

</style>
