<template>
  <div>
    <mwm-slick
      ref="slick"
      :options="slickOptions">
      <reviews-carousel-card v-for="review in currentStore.reviews" :key="review.name" :review="review" />
    </mwm-slick>
</div>
</template>

<script>
import { mapGetters } from 'vuex';

import Slick from 'vue-slick';
import 'slick-carousel/slick/slick.css';

import ReviewsCarouselCard from './ReviewsCarouselCard.vue';

export default {
  components: {
    'mwm-slick': Slick,
    'reviews-carousel-card': ReviewsCarouselCard,
  },
  data: () => ({
    slickOptions: {
      slidesToShow: 1.05,
      arrows: false,
      infinite: false
    }
  }),
  methods: {

  },
  computed: {
    ...mapGetters([
      'currentStore'
    ])
  },
  mounted () {

  }
};
</script>

<style lang="css">

</style>
