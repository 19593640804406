<template>
  <section class="app__fixed-menu" v-show="isVisible">
    <div class="app__fixed-wrap">
      <div class="app__fixed-category">
        <p data-l10n-id="search">{{ currentStore.translation.top_menu_search }}</p>
      </div>
      <div class="app__fixed-logo">
        <v-img contains :src="currentStore.icon" />
      </div>
      <div class="app__fixed-get">
        <div class="app__fixed-get-container" data-l10n-id="in-app-purchases"><span v-html="currentStore.translation.top_menu_cta_side_text" /></div>
        <a @click="sendEventToPixel" class="app__get_button ui-link" data-l10n-id="get">
          <span class="white--text">{{ currentStore.translation.top_menu_cta_text }}</span>
        </a>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';

const uuidv4 = require('uuid/v4')

export default {
  components: {

  },
  data: () => ({
    isVisible: false
  }),
  methods: {
    handleScroll () {
      if (window.scrollY > 150) {
        this.isVisible = true
      } else {
        this.isVisible = false
      }
    },
    sendEventToPixel () {
      const eventId = uuidv4()
      window.fbq('trackSingleCustom', this.currentStore.pixel_id, 'OnClick', { placement: 'top_bar_cta' }, { eventID: eventId });

      window.ttq.track('ClickButton', {})

      this.$store.dispatch('sendEvent', { event_type: 'on_click', value: JSON.stringify({ placement: 'top_bar_cta' }) })

      const payload = {
        source: this.currentStore.id,
        data: [
          {
            event_name: 'OnClick',
            event_id: eventId,
            custom_data: {
              placement: 'top_bar_cta'
            }
          }
        ]
      }
      this.$store.dispatch('sendPageConversionEvent', payload)
        .then(() => {
          const win = window.open(this.currentStore.attribution_url, '_self')
          win.focus()
        })
    }
  },
  computed: {
    ...mapGetters([
      'currentStore'
    ]),
  },
  mounted () {
    setTimeout(() => {
      this.sendEventToPixel()
    }, 200);
  },
  created () {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll);
  }
};
</script>

<style lang="css">
@media (prefers-color-scheme: dark) {
  .app__fixed-menu {
    background: #000!important;
  }

  .app__fixed-get, .app__get {
    color: #818186!important;
  }
}

.app__fixed-menu {
  position: fixed;
  padding: 1.5vw 0;
  background: #fff;
  z-index: 2;
  width: 100%;
}

.visible {
  opacity: 1;
  -webkit-transition: .4s;
  -o-transition: .4s;
  transition: .4s;
}

.app__fixed-menu {
  opacity: 1;
  -webkit-transition: .4s;
  -o-transition: .4s;
  transition: .4s;
}

.app__fixed-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -moz-box-orient: horizontal;
  -moz-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.app__fixed-category {
  padding-left: 6vw;
}

.app__fixed-category p {
  color: #007aff;
  position: relative;
  font-weight: 600;
  text-transform: capitalize;
}

.app__fixed-category p:before {
  content: "";
  border-right: 3px solid #007aff;
  border-top: 3px solid #007aff;
  -webkit-transform: rotate(225deg);
  -ms-transform: rotate(225deg);
  transform: rotate(225deg);
  width: 11px;
  height: 11px;
  position: absolute;
  top: 50%;
  left: -12px;
  margin-top: -6px;
  display: block;
}

.app__fixed-logo {
  width: 29px;
  height: 29px;
}

.app__fixed-logo .v-image__image {
  width: 100%;
  border-radius: 1.5vw;
}

.app__fixed-get-container {
  /* height: 18px; */
  color: #9d9da2;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -moz-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: absolute;
  right: 21vw;
  font-size: 2vw;
  font-weight: 700;
}

.app__fixed-get a {
  background: #007aff;
  border-radius: 28px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  padding: 6px 20px;
  margin-right: 1vw;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  text-align: center;
  min-width: 70px;
}
</style>
