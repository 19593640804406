import { mwmBiPageInstance } from '@/config/api';

export default {
  getPage(payload) {
    const path = `/page/${payload.page_id}`;

    return mwmBiPageInstance.get(path)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error.response));
  },
  getAndroidPage(payload) {
    const path = `/android-page/${payload.android_page_id}`;

    return mwmBiPageInstance.get(path)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error.response));
  },
  getLanding(payload) {
    const path = `/landing/${payload.landing_id}`;

    return mwmBiPageInstance.get(path)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error.response));
  },
  sendPageConversion(payload) {
    return mwmBiPageInstance.post('/cvr/page/get', payload)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error.response));
  },
  sendAPageConversion(payload) {
    return mwmBiPageInstance.post('/cvr/android-page/get', payload)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error.response));
  },
  sendLandingConversion(payload) {
    return mwmBiPageInstance.post('/cvr/landing/get', payload)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error.response));
  },
  sendEvent(payload) {
    return mwmBiPageInstance.post('/ev/', payload)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error.response));
  },
};
